import { React, useContext, useState, useEffect } from "react";
import { AppContext } from "../../../context/AppContext";
import Form from "react-bootstrap/Form";
import Save from "../../../assets/images/save.svg";
import Inspect from "../../../assets/images/inspect.svg";
import Right from "../../../assets/images/right.svg";
import Invoice from "../../../assets/images/invoice.svg";
import DatePicker from "react-datepicker";
import Calendar from "../../../assets/images/calendar.svg";
import uploadIcon from "../../../assets/images/uploadIcon.svg";
import { motion } from "framer-motion";
import { getWithAuthCall, multipartPostCall, putMultipartWithAuthCall, putWithAuthCall, simpleGetCall, simplePUTCall } from "../../../api/ApiServices";
import ApiConfig from "../../../api/ApiConfig";
import moment from "moment";
import { notifyError, notifySuccess } from "../../../sharedComponent/notify";
import { useNavigate, useParams } from "react-router-dom";
import CommonDatePicker from "../../../sharedComponent/CommonDatePicker";
import Loader from "../../../sharedComponent/Loader";

import { useTranslation } from "react-i18next";
// Types of files

const AddFuelExpenses = () => {
  const { sidebar, setSidebar, loading, setLoading } = useContext(AppContext);
  const [validated, setValidated] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const navigate = useNavigate();
  const [VehicalType, setVehicalType] = useState([]);
  const params = useParams();
  let UserId = params.id;
  useEffect(() => {
    if (UserId) {
      geAccessoryDetails();
      geVehicalList()

    } else {
      geVehicalList()
    }
  }, []);
  const handleCancle = () => {
    setAddAccessry({

      date: "",
 
    });
  };
  const [AddAccessry, setAddAccessry] = useState({
    vehicle_id: "",
    current_odometer: "",
    quantity: "",
    amount: "",
    invoice: "",
    date: "",


  });
  const { t, i18n } = useTranslation();

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      HandalAccident(event)
    }
    setValidated(true);
  };
  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
  };



  const geAccessoryDetails = () => {

    setLoading(true)
    simpleGetCall(ApiConfig.VEHICLE_FUEL_PROFLIE + UserId)
      .then((res) => {
        let user_profile = res.data;
        console.log(user_profile);
        setAddAccessry({
          vehicle_id: user_profile.vehicle_fuel_vehicle_id,
          current_odometer: user_profile.vehicle_fuel_current_odometer,
          quantity: user_profile.vehicle_fuel_quantity,
          amount: user_profile.vehicle_fuel_amount,
          date: moment(user_profile.vehicle_fuel_date).utc().format("YYYY-MM-DD"),
          invoice: user_profile.fuel_invoice_no,


        });
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const HandalAccident = (e) => {
    if (UserId) {
      UpdateAccessory(e);
    } else {
      AddCreateAccessory(e);
    }
  };

  const AddCreateAccessory = (e) => {
    e.preventDefault();
    let formData = new FormData();
    formData.append("vehicle_id", AddAccessry.vehicle_id);
    formData.append("current_odometer", AddAccessry.current_odometer);
    formData.append("quantity", AddAccessry.quantity);
    formData.append("amount", AddAccessry.amount);
    formData.append("date", moment(AddAccessry.date).utc().format("YYYY-MM-DD"));
    formData.append("invoice", AddAccessry.invoice);




    multipartPostCall(ApiConfig.VEHICLE_FUEL_ADD, formData)
      .then((res) => {
        if (res.result) {
          notifySuccess(res.message);
          navigate("/FuelExpenses");

        } else {
          notifyError(res.message);
        }
      })
      .catch((err) => console.log(err));
  };

  const UpdateAccessory = (e) => {
    e.preventDefault();
    let requestBody = JSON.stringify({
      fuel_id: Number(UserId),
      date: moment(AddAccessry.date).utc().format("YYYY-MM-DD")

    })
    simplePUTCall(ApiConfig.VEHICLE_FUEL_UPDATE, requestBody)
      .then((res) => {
        if (res.result) {
          notifySuccess(res.message);
          navigate("/FuelExpenses");
        } else {
          notifyError(res.message);
        }
      })
      .catch((err) => console.log(err));
  };
  function geVehicalList() {
    getWithAuthCall(ApiConfig.VEHICLE_ACCIDENT_VEHICALE_LIST)
      .then((data) => {
       setVehicalType(data?.data);
      })
      .catch((error) => {
        console.log("api response", error);
      });
  }
  const handleChange = (e) => {
    if (e.target.name === "invoice") {
      setAddAccessry({
        ...AddAccessry,
        [e.target.name]: e.target.files[0],

      });
    } else setAddAccessry({ ...AddAccessry, [e.target.name]: e.target.value });
  };






  return (
    <>
      <motion.div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
        variants={aninations}
        initial="initial"
        animate="animate"
        exit="exit"
        transition={{ duration: 0.1 }} >
        {loading ? (
          <Loader />
        ) : (
          <>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <div id="cx-wrapper" className="AddFuel_Expenses">
                <div className="main-master-wrapper">
                  <div className="vehicleHead">
                    <p>{UserId ? <>{t("Update Fuel Expenses Details")}</> : <>{t("New Fuel Expenses Details")}</>}</p>
                  </div>
                  <div className="innerInputsGen mainVehAccident ">
                    <div className="addVehicleSe">
                      <div className="addVehicleLeftSec">
                        <div className="row">
                          <div className="col-md-12 form_input_main">
                            <div className="innerSelectBox weekCounter">
                              <Form.Label className="common-labels">
                                {" "}
                                {t("Vehicle Number")} <span>&#42;</span>{" "}
                              </Form.Label>
                              <Form.Select
                                disabled={UserId ? true : false}
                                required
                                placeholder="Enter Your vehicle Name"
                                value={AddAccessry.vehicle_id}
                                onChange={(e) => {
                                  setAddAccessry({
                                    ...AddAccessry,
                                    vehicle_id: e.target.value,
                                  });
                                }}

                              >
                                <option value="">PLease Select vehicle Name</option> 
                                {VehicalType &&
                                  VehicalType?.length &&
                                  VehicalType?.map((Typelist, index) => {
                                    return (
                                      <>
                                        {/* <option value="select">Enter Your vehicle Name</option> */}
                                        <option
                                          key={"delivery" + index}
                                          value={Typelist.vehicle_id}
                                        >
                                          {Typelist.vehicle_number}
                                        </option>
                                      </>
                                    );
                                  })}
                              </Form.Select>
                              <Form.Control.Feedback type="invalid">
                                Please Enter vehicle Number.
                              </Form.Control.Feedback>
                            </div>
                          </div>
                          <div className="col-md-12 form_input_main">
                            <div className="innerSelectBox weekCounter">
                              <Form.Label className="common-labels">
                                {" "}
                                {t("Current Odometer")} <span>&#42;</span>{" "}
                              </Form.Label>
                              <Form.Control
                                required
                                type="text"
                                placeholder="Enter Maintenance Task"
                                disabled={UserId ? true : false}
                                value={AddAccessry.current_odometer}
                                onChange={(e) => {
                                  setAddAccessry({
                                    ...AddAccessry,
                                    current_odometer: e.target.value,
                                  });
                                }}
                              />
                              <Form.Control.Feedback type="invalid">
                                Please Enter Maintenance Task
                              </Form.Control.Feedback>
                            </div>
                          </div>
                          <div className="col-md-12 form_input_main">
                            <div className="innerSelectBox weekCounter">
                              <Form.Label className="common-labels">
                                {" "}
                                {t("Quantity")}  <span>&#42;</span>{" "}
                              </Form.Label>
                              <Form.Control
                                required
                                type="text"
                                placeholder="Enter Quantity in number"
                                disabled={UserId ? true : false}
                                value={AddAccessry?.quantity}
                                onChange={(e) => {
                                  const inputValue = e.target.value.replace(/[^0-9]/g, '');
                                  setAddAccessry({
                                    ...AddAccessry,
                                    quantity: inputValue,
                                  });
                                }}
                              />
                              <Form.Control.Feedback type="invalid">
                                Please Enter Quantity.
                              </Form.Control.Feedback>
                            </div>
                          </div>
                          <div className="col-md-12 form_input_main">
                            <div className="innerSelectBox weekCounter">
                              <Form.Label className="common-labels">
                                {" "}
                                {t("Amount")} <span>&#42;</span>{" "}
                              </Form.Label>
                              <Form.Control
                                required
                                type="text"
                                placeholder="Enter Amount in Number"
                                disabled={UserId ? true : false}
                                value={AddAccessry?.amount}
                                onChange={(e) => {
                                  const inputValue = e.target.value.replace(/[^0-9]/g, '');
                                  setAddAccessry({
                                    ...AddAccessry,
                                    amount:inputValue,
                                  });
                                }}
                              />
                              <Form.Control.Feedback type="invalid">
                                Please Enter Amount
                              </Form.Control.Feedback>
                            </div>
                          </div>
                          <div className="col-md-12 form_input_main">
                          <Form.Label className="common-labels">
                                {" "}
                                {t("Date")} <span>&#42;</span>
                              </Form.Label>
                            <div className="innerSelectBox weekCounter datepicker-main">
                          
                              <CommonDatePicker setDate={setAddAccessry} data={AddAccessry} dateKey="date" />

                            
                              <Form.Control.Feedback type="invalid">
                                Please Select Date.
                              </Form.Control.Feedback>
                   
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* This section is for image addidtion in an array on every selection */}
                      <div className="imagesRightSec">
                        <div className="row">
                          <div className="col-md-12 form_input_main">

                          </div>
                          <div className="col-md-12 form_input_main">

                            {/* Selected image preview here */}
                            <div className="previewImg">
                              <img
                                src={
                                  !AddAccessry.invoice
                                    ? Invoice
                                    : AddAccessry.invoice.length
                                      ? AddAccessry.invoice
                                      : AddAccessry.invoice &&
                                      URL.createObjectURL(
                                        AddAccessry.invoice
                                      )
                                }
                                className="InvoiceImg" alt="" />
                            </div>
                          </div>
                          <div className="col-md-12 form_input_main">
                            <div className="fileDropper">
                              <label htmlFor="" className="imageHolder">
                                {/* <input type="file" id="file" className="d-none" disabled={UserId ? true : false} /> */}
                                <div className="innerFlex">
                                  {/* <img
                                    src={uploadIcon}
                                    className="browseImg"
                                    alt=""
                                  />
                                  <p className="innerTxt">{t("Drag & Drop Your File")}</p>
                                  <p className="innerTxt">{t("Or")}</p> */}
                                  <label htmlFor="invoice" className="browseBtn">
                                    <input
                                      // disabled={UserId ? true : false}
                                      type="file"


                                      id="invoice"
                                      name="invoice"

                                      className="d-none"
                                      onChange={handleChange}
                                    />
                                    <p className="mt-1">{t("Browse File")}</p>
                                  </label>
                                </div>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Two Bottom Buttons */}
                  <div className="d-flex justify-content-end align-items-center belowBtns">
                    <button className="cx-btn-1"
                      type="button"
                 
                      onClick={() => handleCancle()}
                    >{t("Cancel")}</button>
                    <button className="cx-btn-2">{UserId ? <>{t("Update")}</> : <>{t("Submit")}</>}</button>
                  </div>
                </div>
              </div>
            </Form>
          </>)}
      </motion.div>
    </>
  );
};

export default AddFuelExpenses;
