import { React, useContext, useState } from 'react'
import { AppContext } from '../../../context/AppContext'
import DDlogo from '../../../assets/images/smallDD.svg'
import Calendar from '../../../assets/images/calendar.svg'
import DatePicker from 'react-datepicker'
import { Link } from 'react-router-dom'
import SideIc from '../../../assets/images/sideBar.svg'
import Delete from '../../../assets/images/delete.svg'
import export_icon from '../../../assets/images/export_icon.svg'
import View from '../../../assets/images/Group.svg'
import EditIc from '../../../assets/images/ic-edit.svg'
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap'
import Form from 'react-bootstrap/Form'
import { motion } from 'framer-motion'
import Pagenation from '../../../sharedComponent/Pagenation'
import { simpleGetCall, simplePostCall } from '../../../api/ApiServices'
import ApiConfig from '../../../api/ApiConfig'
import { useEffect } from 'react'
import { notifyError, notifySuccess } from '../../../sharedComponent/notify'
import { simpleDeleteCall } from '../../../api/ApiServices'
import CommonDatePicker from '../../../sharedComponent/CommonDatePicker'
import { DateDDMMYYYY } from '../../../sharedComponent/common'
import Loader from '../../../sharedComponent/Loader'
import NoDataComp from '../../../sharedComponent/NoDataComp'
import { useTranslation } from 'react-i18next'
import { jsPDF } from 'jspdf'
import { useSelector } from 'react-redux'

const VehicleAccident = () => {
  const accessRights = useSelector(state => state.auth.accessRights)
  // const userRole = accessRights && accessRights.rights_role
  const userRole = "no Role"

  const { sidebar, setSidebar, Dark, setDark,recordsPerPage } = useContext(AppContext)
  const [startDate, setStartDate] = useState('')
  const [deleteModal, setDeleteModal] = useState(false)
  const [FilterName, setFiltertName] = useState('')
  const [tripName, setFiltertTrip] = useState('')
  const [tripLocation, setFiltertlocation] = useState('')
  const { t, i18n } = useTranslation()

  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 }
  }
  const [last_page, setlast_page] = useState(false)
 const renderTooltipForEdit = props => (
    <Tooltip id='button-tooltip' {...props}>
      {t('Edit')}
    </Tooltip>
  )
  const renderTooltipForView = props => (
    <Tooltip id='button-tooltip' {...props}>
      {t('View')}
    </Tooltip>
  )
  const renderTooltipForDelete = props => (
    <Tooltip id='button-tooltip' {...props}>
      {t('Delete')}
    </Tooltip>
  )
  const [TransportList, setTransportList] = useState([])

  const [page, setPage] = useState(1)
  const [DeleteId, setDeleteId] = useState('')
  const [loading, setLoading] = useState(false)
  const [totalPages, setTotalPages] = useState(0)
  useEffect(() => {
    handalFilter()
  }, [FilterName, tripName, tripLocation, startDate])
  useEffect(() => {
    if (page === 1) {
      getVechicleAccident(page)
      setLoading(true)
    }
  }, [])

  function getVechicleAccident (currentPage) {
    // page == 1 && !currentPage && setLoading(true);

    let newRequestBody = JSON.stringify({
      page: currentPage,
      page_limit: recordsPerPage
    })
    simplePostCall(ApiConfig.VEHICLE_ACCIDENT_LIST, newRequestBody)
      .then(data => {
       if (data.result) {
          setTotalPages(data.total_count)
          setlast_page(data.last_page)
          let coursesData = data.data ? data.data : []
          let LearningData = coursesData.map((eachteam, index) => {
            return {
              ...eachteam,
              user_profile_pic: eachteam.user_profile_pic || '',
              user_name: eachteam.user_name || '',
              user_email: eachteam.user_email || ''
            }
          })

          if (currentPage === 1) {
            setTransportList(data.data)
          } else {
            setTransportList([...TransportList, ...LearningData])
            // setlast_page(false)
          }
        } else {
          setTransportList(0)
        }
      })
      .catch(err => {
        console.log('err', err)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  function userDelete () {
    let newRequestBody = JSON.stringify({
      incident_id: DeleteId
    })
    simpleDeleteCall(ApiConfig.VEHICLE_ASSISTANTS, newRequestBody)
      .then(data => {
        if (data.result) {
          notifySuccess(data.message)
          setDeleteModal(false)
          getVechicleAccident(1)
          setPage(1)
        } else {
          notifyError(data.message)
        }
      })
      .catch(error => {
        console.log('api response', error)
      })
  }

  function handalFilter () {
    let newRequestBody = JSON.stringify({
      page: '',
      vehicle_name: FilterName,
      trip_name: tripName,
      location: tripLocation,
      date: startDate
    })
    simplePostCall(ApiConfig.VEHICLE_ACCIDENT_FILTER, newRequestBody)
      .then(data => {
        if (data.result) {
          setTotalPages(data.total_count)
          setTransportList(data.data)
        } else {
          // notifyError(data.message);
          setTransportList(0)
          setTotalPages(0)
        }
      })
      .catch(error => {
        console.log('api response', error)
      })
  }

  function onPageLoad () {
    setPage(page + 1)
    getVechicleAccident(page + 1)
  }

  /// Export

  function handalExport () {
    if (
      FilterName === '' ||
      tripName === '' ||
      tripLocation === '' ||
      startDate === ''
    ) {
      handalFilterExport()
    } else {
      getExportChat()
    }
  }

  function getExportChat () {
    simplePostCall(ApiConfig.VEHICLE_ACCIDENT_EXPORT)
      .then(data => {
            if (data.result) {
          pdfFormat(data.data)
        } else {
        }
      })
      .catch(err => {
        console.log('err', err)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  function handalFilterExport () {
    let newRequestBody = JSON.stringify({
      vehicle_name: FilterName,
      trip_name: tripName,
      location: tripLocation,
      date: startDate
    })
    simplePostCall(ApiConfig.VEHICLE_EXSIDENT_EXPORT, newRequestBody)
      .then(data => {
        if (data.result) {
          pdfFormat(data.data)
        } else {
        }
      })
      .catch(error => {
        console.log('api response', error)
      })
  }

  const pdfFormat = pdfData => {
   // let chatsData = await getExportChat()
    const unit = 'pt'
    const size = 'A4' // Use A1, A2, A3 or A4
    const orientation = 'portrait' // portrait or landscape
    const marginLeft = 40
    const doc = new jsPDF(orientation, unit, size)
    doc.setFontSize(15)
    const title = 'Vehicle Accident'
    const headers = [['Sr. No.', 'Vehicle Name', 'Expense', 'Location']]
    var data = []

    pdfData.map((item, index) => {
      data.push([
        index + 1,
        item.vehicle_number,
        item.incident_expense,
        item.incident_location
      ])
    })

    let content = {
      headStyles: { fillColor: '#9c4900' },
      theme: 'grid',
      pageBreak: 'auto',
      bodyStyles: { fillColor: '#f6efe9' },
      styles: { fillColor: '#9c4900' },
      head: headers,
      title: title,
      body: data
    }

    doc.text(title, marginLeft, 25)
    doc.autoTable(content)
    doc.save('VT.pdf')
    return <div></div>
  }

  return (
    <>
      <motion.div
        className={sidebar ? 'taskMain ' : 'cx-active taskMain'}
        id='cx-main'
        variants={aninations}
        initial='initial'
        animate='animate'
        exit='exit'
        transition={{ duration: 0.1 }}
      >
      
            <div id='cx-wrapper' className='Vehicle_Accident'>
              <div className='displayFlexInp'>
                <div className='innerSelectBox weekCounter selectForm form_input_main'>
                  <Form.Control
                    required
                    type='text'
                    placeholder='Vehicle Name'
                    className='innerCust'
                    onChange={e => {
                      setFiltertName(e.target.value)
                    }}
                  />
                </div>
                {/* <div className='innerSelectBox weekCounter selectForm form_input_main'> */}
                  {/* <Form.Select
                    required
                    as='select'
                    type='select'
                    name='Speed_limit'
                    className='innerCust'
                  >
                    <option value=''>Trip Name</option>
                    <option value='50'>50</option>
                    <option value='100'>100</option>
                    <option value='150'>150</option>
                  </Form.Select> */}
                  {/* <Form.Control
                    required
                    type="number"
                    placeholder="Vehicle Trip"
                    className="innerCust"
                    onChange={(e) => {
                      setFiltertTrip(e.target.value);
                    }}
                  /> */}
                {/* </div> */}
                <div className='innerSelectBox weekCounter selectForm form_input_main'>
                  <Form.Control
                    required
                    type='text'
                    placeholder='Location, Description, Expenses...'
                    className='innerCust'
                    onChange={e => {
                      setFiltertlocation(e.target.value)
                    }}
                  />
                </div>
                <div className='innerSelectBox weekCounter selectForm form_input_main datepicker-main'>
                  <input
                    class='datepicker-here form-control digits'
                    type='date'
                    data-language='en'
                    placeholder='MM-DD-YYYY'
                    data-position='top left'
                    name='submission_date'
                    value={startDate}
                    // onChange={(date) => setStartDate(date)}
                    onChange={e => setStartDate(e.target.value)}
                  />
                  {/* <img src={Calendar} className="calendarLogo" alt="" /> */}
                </div>
                <div className='innerSelectBox weekCounter selectForm form_input_main'>
                  {userRole === 'customer' ||
                  (accessRights && accessRights?.rights_manage_accident) ? (
                    <Link to='/AddVehicleAccident' className='AddAccidentLink'>
                      <button className='innerCust'>
                        + {t('Add Accident')}
                      </button>
                    </Link>
                  ) : null}
                </div>
                {/* <div className='headerDivIc form_input_main'>
                  <Link to='#'>
                    <img src={SideIc} alt='' />
                  </Link>
                </div> */}
                <div className='headerDivIc form_input_main'>
                  <Link to='#'>
                    <img
                      onClick={() => handalExport()}
                      src={export_icon}
                      alt=''
                    />
                  </Link>
                </div>
              </div>
              {/* Top inputs for instatnt search */}

              {/* Vehicle table */}

              {loading ? (
          <Loader />
        ) : (
          <>      <div className='main-master-wrapper'>
                <div
                  id='scroll_insideThe_Padding_tabel'
                  onScroll={e => {
                    const bottom =
                      e.target.scrollHeight - e.target.scrollTop ===
                      e.target.clientHeight

                    if (bottom && !last_page) onPageLoad()
                  }}
                >
                  <div className='VA_table'>
                    <table className='table tableAdmin'>
                      <thead className='tableHead'>
                        <tr>
                          <th>{t('Sr.no')}</th>
                          <th>{t('Vehicle Name')}</th>
                          {/* <th>{t("Trip Name")}</th> */}
                          <th>{t('Location')}</th>
                          <th>{t('Description')}</th>
                          <th>{t('Expenses')}</th>
                          <th>{t('Date')}</th>
                          <th>{t('Action')}</th>
                        </tr>
                      </thead>
                      {TransportList && TransportList.length > 0 ? (
                        TransportList.map((itemlist, index) => {
                          return (
                            <tbody className='tableBody'>
                              <tr>
                                <td>{index + 1}</td>
                                <td>{itemlist?.vehicle_number}</td>
                                {/* <td>{itemlist.trip_name}</td> */}
                                <td>{itemlist?.incident_location}</td>
                                <td>{itemlist?.incident_description}</td>
                                <td>{itemlist?.incident_expense}</td>
                                <td>{itemlist?.incident_date&&DateDDMMYYYY(itemlist?.incident_date)}</td>
                                <td>
                                  <div className='innerFlex d-flex align-items-center'>
                                    <OverlayTrigger
                                      placement='bottom'
                                      delay={{ show: 250, hide: 400 }}
                                      overlay={renderTooltipForView}
                                    >
                                      <Link
                                        // to="/ViewVehicleAccident"
                                        to={
                                          '/ViewVehicleAccident/' +
                                          itemlist.incident_id
                                        }
                                      >
                                        <div className='inconsIn me-3'>
                                          <img src={View} alt='' />
                                        </div>
                                      </Link>
                                    </OverlayTrigger>
                                    {userRole === 'customer' ||
                                    (accessRights &&
                                      accessRights?.rights_manage_accident) ? (
                                      <>
                                        <OverlayTrigger
                                          placement='bottom'
                                          delay={{ show: 250, hide: 400 }}
                                          overlay={renderTooltipForEdit}
                                        >
                                          <Link
                                            to={
                                              '/AddVehicleAccident/' +
                                              itemlist.incident_id
                                            }
                                          >
                                            <div className='inconsIn me-3'>
                                              <img src={EditIc} alt='' />
                                            </div>
                                          </Link>
                                        </OverlayTrigger>
                                        <OverlayTrigger
                                          placement='bottom'
                                          delay={{ show: 250, hide: 400 }}
                                          overlay={renderTooltipForDelete}
                                        >
                                          <Link
                                            to='#'
                                            onClick={() => {
                                              setDeleteId(itemlist.incident_id)
                                              setDeleteModal(true)
                                            }}
                                          >
                                            <div className='inconsIn'>
                                              <img src={Delete} alt='' />
                                            </div>
                                          </Link>
                                        </OverlayTrigger>
                                      </>
                                    ) : null}
                                    {/* <Link to="/ViewVehicleAccident">
                            <img src={View} className="me-3" alt="" />
                          </Link>
                          <Link to="/EditVehicleAccident">
                            <img src={EditIc} alt="" className="me-3" />
                          </Link>
                          <Link to="#" onClick={() => setDeleteModal(true)}>
                            <img src={Delete} alt="" />
                          </Link> */}
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          )
                        })
                      ) : (
                        <></>
                      )}
                    </table>
                  </div>
                  {TransportList.length > 0 ? '' : <NoDataComp />}
                </div>
                {/* {last_page === true ? (
                  <p
                    style={{
                      textAlign: 'center',
                      marginTop: '20px',
                      color: '#9c4900'
                    }}
                  >
                    <b>No More data Found</b>
                  </p>
                ) : (
                  ''
                )} */}
               { TransportList?.length>0&& <Pagenation length={TransportList?.length} total={totalPages} />}
              </div>
              </>
        )}
            </div>
     
      </motion.div>

      {/* Delete Modal Start */}
      <Modal
        show={deleteModal}
        onHide={() => setDeleteModal(false)}
        centered
        className='common-model'
      >
        <Modal.Header closeButton>
          <Modal.Title>{t('Delete Vehicle Accident')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t('Are you sure you want to Delete this Vehicle Accident')} ?
        </Modal.Body>
        <Modal.Footer className='pop-up-modal-footer'>
          <div class='btn-wrapper'>
            <button className='cx-btn-1' onClick={() => setDeleteModal(false)}>
              {t('Cancel')}
            </button>
            <button className='cx-btn-2' onClick={userDelete}>
              Yes
            </button>
          </div>
        </Modal.Footer>
      </Modal>
      {/* Delete Modal End */}
    </>
  )
}

export default VehicleAccident
