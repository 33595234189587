import React, { useContext, useState, useEffect, Fragment } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Dropdown, Tab, Tabs } from "react-bootstrap";
import { Link } from "react-router-dom";
import { AppContext } from "../../../../context/AppContext";
import View from "../../../../assets/images/Group.svg";
import Export from "../../../../assets/images/Edit-Camunication.svg";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import FileSaver from "file-saver";
import { getDrivers } from "../../../../store/driverSlice";
import { getvehicleList } from "../../../../store/vehicleListSlice";
import { getTripActivity } from "../../../../store/tripActivitySlice";
import { getDriverRanking } from "../../../../store/driverRankingSlice";
import { getVehicleAlert } from "../../../../store/VehicleAlertSlice";
import { getAcceleration } from "../../../../store/accelerationSlice";
import {
  ConvertKilometersToMiles,
  DateDDMMYYYY,
  TimeFormat,
  TimeFormatForAll,
  addInterval,
  latestDate,
} from "../../../../sharedComponent/common";
import CommonDatePicker from "../../../../sharedComponent/CommonDatePicker";
import Import from "../../../../assets/images/ic-Import.svg";
import ApiConfig from "../../../../api/ApiConfig";
import {
  getWithAuthCall,
  simpleGetCall,
  simplePostCall,
} from "../../../../api/ApiServices";
import { notifyError } from "../../../../sharedComponent/notify";
import { Value } from "sass";
import Loader from "../../../../sharedComponent/Loader";
import NoDataComp from "../../../../sharedComponent/NoDataComp";

const TripActivityReport = () => {
  const [selectedDriver, setSelectedDriver] = useState(0);
  // const [vehicleList, setvehicleList] = useState([]);
  const [loading, setLoading] = useState(false);
  const drivers = useSelector((state) => state.driver.drivers);
  // const vehicleList = useSelector((state) => state.vehicleList.vehicleList);
  const [vehicleList, setvehicleList] = useState([]);
  const tripActivity = useSelector((state) => state.tripActivity.tripActivity);

  const {
    sidebar,
    setSidebar,
    customerData,
    LinkReport,
    setOptionDynamicDownload,
    setDark,
  } = useContext(AppContext);
  console.log("customerData33333333333333", customerData);
  console.log("LinkReport  >>>>>>>>>>>  ", LinkReport);
  /*  const getdistanceunit = localStorage.customer_distance_unit
    ? localStorage.customer_distance_unit
    : ""; */
  const { t, i18n } = useTranslation();
  const getdistanceunit = localStorage.getItem("customer_distance_unit") || "";
  const translatedDistanceUnit = t(getdistanceunit);

  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
  };

  const dispatch = useDispatch();
  const params = useParams();
  const maxDate = new Date();

  const [startDate, setStartDate] = useState({ DateToday: new Date() });
  const [OrderListData, setOrderListData] = useState([]);
  const [pdfData, setpdfData] = useState("");
  console.log("pdfData", pdfData);
  const [NextPage, setNextPage] = useState(true);
  console.log("NextPage", NextPage);
  const [currentDate, setCurrentDate] = useState({ toDayDate: new Date() });
  const [optionData, setOptionData] = useState("date");
  const [VehicleId, setVehicleId] = useState("");
  const [DriverId, setDriverId] = useState("");
  const [DriverList, setDriverList] = useState([]);
  console.log(optionData);
  const [daysToAdd, setDaysToAdd] = useState(30);
  const [NextDateShow, setNextDateShow] = useState({ toNextDate: new Date() });
  const [firstDayOfMonth, setFirstDayOfMonth] = useState(new Date());
  const [endDayOfMonth, setEndDayOfMonth] = useState(new Date());
  const [OptionDownload, setOptionDownload] = useState("");
  const [EndDate, setEndDate] = useState({ toDayEnd: new Date() });

  // State for weekStartDate and weekEndDate
  const [weekStartDate, setWeekStartDate] = useState(new Date());

  const [weekEndDate, setWeekEndDate] = useState(new Date());
  const [alltripList, setalltripList] = useState([]);
  const [TripId, setTripId] = useState("");

  // Match current date and update number of days based on the selected option
  useEffect(() => {
    setNextDateShow({ toNextDate: currentDate.toDayDate });
    // const currentDate = moment();

    // console.log("checking", currentDate.isSame(currentDate, `${optionData == 'month' ? 'month':  optionData == 'week' ? 'week' : optionData == 'date' ? 'date' : ''  }`))
    switch (optionData) {
      case "month":
        setDaysToAdd(29);
        break;
      case "week":
        setDaysToAdd(7);
        break;
      case "date":
        setDaysToAdd(1);
        break;
      default:
        setDaysToAdd(30); // Default to 30 days for month
        break;
    }
  }, [optionData, currentDate, EndDate]);

  // Pdf  and Exial

  // Api List UseEffacdt

  useEffect(() => {
    if (TripId) {
      reportViewList(
        currentDate.toDayDate,
        firstDayOfMonth,
        endDayOfMonth,
        NextDateShow.toNextDate,
        optionData,
        weekStartDate,
        weekEndDate,
        LinkReport
      );
    } else {
      reportViewList(
        currentDate.toDayDate,
        firstDayOfMonth,
        endDayOfMonth,
        NextDateShow.toNextDate,
        optionData,
        weekStartDate,
        weekEndDate
      );
    }
  }, [
    weekEndDate,
    weekStartDate,
    // currentDate,
    NextDateShow,
    firstDayOfMonth,
    optionData,
    endDayOfMonth,
  ]);

  useEffect(() => {}, [LinkReport]);
  useEffect(() => {
    if (TripId) {
      reportViewList();
    }
  }, [TripId]);

  useEffect(() => {
    if (OptionDownload === "pdf" || OptionDownload === "excel") {
      reportViewList();
    }
  }, [OptionDownload]);

  const reportViewList = () => {
    let requestData;

    requestData = {
      format: OptionDownload,
      startdate: latestDate(currentDate.toDayDate, "yyyy-MM-dd"),
      enddate: latestDate(EndDate.toDayEnd, "yyyy-MM-dd"),
      pageid: 1,
      trip_id: TripId,
    };

    if (requestData) {
      {
        OptionDownload == "pdf" || OptionDownload == "excel"
          ? setLoading(false)
          : setLoading(true);
      }
      setOptionDownload("");
      simplePostCall(ApiConfig.TRIP_ACTIVITY, JSON.stringify(requestData))
        .then((res) => {
          if (res.result) {
            console.log(res.result);
            setLoading(false);
            setOptionDynamicDownload("");
            // Extract the array of items from the response
            const firstKey = res.filepath;

            console.log(pdfData);
            setpdfData(firstKey);
            if (!firstKey) {
              setOrderListData(res.listData ? res.listData : res.data);
            }
            // Set the array to the state
          } else {
            setOptionDownload("");
            // notifyError(res.message)
            // Handle the case when the result is false
            // notifyError(res.message);
          }
        })
        .catch((err) => {
          console.log("Error:", err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    getAlltrip();
  }, [,]);

  function getAlltrip() {
    simplePostCall(ApiConfig.TRIP_LIST_REPORT)
      .then((data) => {
        console.log(data, "data222222222222222222222222222");
        setalltripList(data?.listData);
      })
      .catch((error) => {
        console.log("api response", error);
      });
  }
  // // Driver Dropdown List
  // function getDriverList() {
  //   simpleGetCall(ApiConfig.GET_ALL_DRIVERS)
  //     .then((data) => {
  //       // setVehicalType(data?.data);
  //       setDriverList(data?.data);
  //     })
  //     .catch((error) => {
  //       console.log("api response", error);
  //     });
  // }

  useEffect(() => {
    if (pdfData) {
      downloadFile();
    }
  }, [pdfData]);

  const downloadFile = () => {
    fetch(pdfData)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));

        const a = document.createElement("a");
        a.href = url;

        // Extract the file name from the filepath dynamically
        const fileName = pdfData.substring(pdfData.lastIndexOf("/") + 1);
        a.download = fileName;

        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => console.error("Error downloading file:", error));
  };

  return (
    <motion.div
      className={sidebar ? "taskMain " : "cx-active taskMain"}
      id="cx-main"
      variants={aninations}
      initial="initial"
      animate="animate"
      exit="exit"
      transition={{ duration: 0.1 }}
    >
      <div id="cx-wrapper" className="Vehicle_inspition_dashboard heightFixed">
        <div className="report-viwe-head" id="Viwe_Report_Respoasive">
          <p></p>
        </div>
        {/* <div className="col-lg-12 ">
          <div className="middle-header">
            <p> Vehicle Running Summary Report</p>
          </div>
        </div> */}
        {/* New UI ADD */}

        <div className="col-lg-12 mt-0">
          <div className="UserTabel">
            <div className="heading">
              <div>
                <select
                style={{width:"300px"}}
                  className="form-select "
                  aria-label="Default select example"
                  placeholder="trip list"
                  onChange={(e) => setTripId(e.target.value)}
                >
                  <option value="">{t("Trip ID")}</option>
                  {alltripList.map((vehicle) => (
                    <option Value={vehicle.trip_id}>{vehicle.trip_id}</option>
                  ))}
                </select>
              </div>
              <div className="innerSelectBox weekCounter datepicker-main">
                <CommonDatePicker
                  dateKey={"toDayDate"}
                  setDate={setCurrentDate}
                  data={currentDate}
                  SetPlaceholder={t("Today Date")}
                  dataDisbal={maxDate}
                />
              </div>
              <div className="innerSelectBox weekCounter datepicker-main">
                <CommonDatePicker
                  dateKey={"toDayEnd"}
                  setDate={setEndDate}
                  data={EndDate}
                  SetPlaceholder={t("Today End")}
                  dataDisbal={maxDate}
                />
              </div>

              <div className="leftContent d-flex">
                <div class="btn-wrapper"></div>
                <div></div>
              </div>

              <div className="right-export-btn-section-wrapper">
                <div className="c-pointer me-2"></div>

                <div className="md_dropdown">
                  <Dropdown>
                    <Dropdown.Toggle id="dropdown-basic">
                      <img src={Import} alt="" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item>
                        <Link
                          onClick={() => setOptionDownload("pdf")}
                          className="d-block"
                        >
                          {t("PDF")}
                        </Link>
                      </Dropdown.Item>
                      <Dropdown.Item>
                        <Link
                          onClick={() => setOptionDownload("excel")}
                          className="d-block"
                        >
                          {t("Excel")}
                        </Link>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>
            <table className="table tableAdmin">
              <thead className="tableHead">
                <tr>
                  <th>{t("Sr.no")}</th>
                  <th>{t("Trip ID")}</th>
                  <th>{t("Trip Start Date")}</th>
                 
                  <th>{t("Start Time")}</th>
                  <th>{t("Start Location")}</th>
                  <th>{t("End Time")}</th>
                  <th>{t("Trip End Date")}</th>
                  <th>{t("End Location")}</th>
                  <th>{t("Duration")}</th>
                  <th>{t("Distance")}</th>
                  <th>{t("Status")}</th>
                </tr>
              </thead>
              {loading ? (
                <Loader />
              ) : (
                <>
                  <tbody className="tableBody">
                    {OrderListData && OrderListData?.length > 0 ? (
                      OrderListData?.map((itemlist, index) => {
                        return (
                          <>
                            <tr>
                              <td>{index + 1}</td>
                              
                              <td>
                                {itemlist.trip_id }
                              </td>
                              <td>
                                {itemlist.trip_date}
                              </td>
                              <td>
                                {itemlist.trip_start_time }
                              </td>
                             
                              <td>
                                {itemlist.vehicle_activity_start_location
                                  ? itemlist.vehicle_activity_start_location
                                  : ""}
                              </td>

                              <td>
                                {itemlist.trip_end_time}
                              </td>
                              <td>
                                {itemlist.trip_end_date }
                              </td>
                              <td>
                                {itemlist.vehicle_activity_end_location
                                  ? itemlist.vehicle_activity_end_location
                                  : ""}
                              </td>
                              <td>
                                {itemlist.duration}
                              </td>
                              <td>{itemlist.distance}</td>
                              <td>
                                {itemlist.vehicle_activity_status
                                   }
                              </td>
                            </tr>
                          </>
                        );
                      })
                    ) : (
                      <div className="">{/* <NoDataComp /> */}</div>
                    )}
                  </tbody>
                </>
              )}
            </table>

            {OrderListData?.length === 0 && !loading && <NoDataComp />}
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default TripActivityReport;
