import { React, useContext, useState, useEffect } from "react";
import { AppContext } from "../../../context/AppContext";
import Form from "react-bootstrap/Form";
import Save from "../../../assets/images/save.svg";
import Inspect from "../../../assets/images/inspect.svg";
import Right from "../../../assets/images/right.svg";
import Invoice from "../../../assets/images/invoice.svg";
import DatePicker from "react-datepicker";
import Calendar from "../../../assets/images/calendar.svg";
import uploadIcon from "../../../assets/images/uploadIcon.svg";
import { motion } from "framer-motion";
import { useNavigate, useParams } from "react-router-dom";
import {
  getWithAuthCall,
  multipartPostCall,
  simpleGetCall,
  simplePUTCall,
  simplePostCall,
} from "../../../api/ApiServices";
import ApiConfig from "../../../api/ApiConfig";
import moment from "moment";
import { notifyError, notifySuccess } from "../../../sharedComponent/notify";
import CommonDatePicker from "../../../sharedComponent/CommonDatePicker";
import Loader from "../../../sharedComponent/Loader";

import { useTranslation } from "react-i18next";
// Types of files

const AddVehicleAccesory = () => {
  const { sidebar, setSidebar, Dark, setDark, loading, setLoading } =
    useContext(AppContext);
  const [validated, setValidated] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const navigate = useNavigate();
  const [VehicalType, setVehicalType] = useState([]);

  const [AddAccessry, setAddAccessry] = useState({
    vehicle_id: "",
    invoice: "",
    purchase_date: "",
    purchase_from: "",
    quantity: "",
    accessory_name: "",
    price: "",
  });
  const handleCancle = () => {
    setAddAccessry({
      purchase_date: "",
    });
  };
  const { t, i18n } = useTranslation();

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      HandalAccident(event);
    }
    setValidated(true);
  };
  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
  };

  const params = useParams();
  let UserId = params.id;
  useEffect(() => {
    if (UserId) {
      geAccessoryDetails();
      geVehicalList();
    } else {
      geVehicalList();
    }
  }, []);

  const geAccessoryDetails = () => {
    setLoading(true);
    simpleGetCall(ApiConfig.Vehicle_ACCESSORY_Profile + UserId)
      .then((res) => {
        let user_profile = res.data;

        setAddAccessry({
          vehicle_id: user_profile.accessory_vehicle_id,
          invoice: user_profile.accessory_invoice_no,
          accessory_name: user_profile.vehicle_number,
          purchase_from: user_profile.accessory_purchase_from,
          // route_id: user_profile.route_id,
          quantity: user_profile.accessory_quantity,
          price: user_profile.accessory_price,
          purchase_date: moment(user_profile.accessory_purchase_date)
            .utc()
            .format("YYYY-MM-DD"),
        });
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const HandalAccident = (e) => {
    if (UserId) {
      UpdateAccessory(e);
    } else {
      AddCreateAccessory(e);
    }
  };

  const AddCreateAccessory = (e) => {
    e.preventDefault();
    let formData = new FormData();
    formData.append("vehicle_id", AddAccessry.vehicle_id);
    formData.append("invoice", AddAccessry.invoice);
    formData.append(
      "purchase_date",
      moment(AddAccessry.purchase_date).utc().format("YYYY-MM-DD")
    );
    formData.append("purchase_from", AddAccessry.purchase_from);
    // formData.append("route_id", AddAccessry.route_id);
    formData.append("quantity", AddAccessry.quantity);
    formData.append("accessory_name", AddAccessry.accessory_name);
    formData.append("price", AddAccessry.price);

    multipartPostCall(ApiConfig.Vehicle_ACCESSORY_ADD, formData)
      .then((res) => {
        if (res.result) {
          notifySuccess(res.message);
          navigate("/VehicleAccesory");
        } else {
          notifyError(res.message);
        }
      })
      .catch((err) => console.log(err));
  };

  const UpdateAccessory = (e) => {
    e.preventDefault();

    // let newRequestBody = JSON.stringify({
    //   accessory_id:UserId,
    //   invoice:AddAccessry.invoice,
    //   purchase_date: AddAccessry.purchase_date,
    // });
    let formData = new FormData();
    formData.append("accessory_id", UserId);
    formData.append("invoice", AddAccessry.invoice);
    formData.append(
      "purchase_date",
      moment(AddAccessry.purchase_date).utc().format("YYYY-MM-DD")
    );

    multipartPostCall(ApiConfig.Vehicle_ACCESSORY_Update, formData)
      .then((res) => {
        if (res.result) {
          notifySuccess(res.message);
          navigate("/VehicleAccesory");
        } else {
          notifyError(res.message);
        }
      })
      .catch((err) => console.log(err));
  };
  function geVehicalList() {
    getWithAuthCall(ApiConfig.VEHICLE_ACCIDENT_VEHICALE_LIST)
      .then((data) => {
        console.log(data);

        setVehicalType(data.data);
      })
      .catch((error) => {
        console.log("api response", error);
      });
  }
  const handleChange = (e) => {
    if (e.target.name === "invoice") {
      setAddAccessry({
        ...AddAccessry,
        [e.target.name]: e.target.files[0],
      });
    } else setAddAccessry({ ...AddAccessry, [e.target.name]: e.target.value });
  };
  return (
    <>
      <motion.div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
        variants={aninations}
        initial="initial"
        animate="animate"
        exit="exit"
        transition={{ duration: 0.1 }}
      >
        {loading ? (
          <Loader />
        ) : (
          <>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <div id="cx-wrapper" className="AddVehicle_Accesory">
                <div className="main-master-wrapper">
                  <div className="vehicleHead">
                    <p>
                      {UserId ? "Update " : "New"} Vehicle Accessory Details
                    </p>
                  </div>
                  <div className="innerInputsGen mainVehAccident ">
                    <div className="addVehicleSe">
                      <div className="addVehicleLeftSec">
                        <div className="row">
                          <div className="col-md-12 form_input_main">
                            <div className="innerSelectBox weekCounter">
                              <Form.Label className="common-labels">
                                {" "}
                                {t("Vehicle Name")} <span>&#42;</span>{" "}
                              </Form.Label>

                              <Form.Select
                                disabled={UserId ? true : false}
                                required
                                placeholder="Enter Your vehicle Name"
                                value={AddAccessry?.vehicle_id}
                                onChange={(e) => {
                                  setAddAccessry({
                                    ...AddAccessry,
                                    vehicle_id: e.target.value,
                                  });
                                }}
                              >
                                <option value="">
                                  Please select vehicle Name
                                </option>
                                {VehicalType &&
                                  VehicalType?.length &&
                                  VehicalType?.map((Typelist, index) => {
                                    return (
                                      <>
                                        {/* <option value="select">Enter Your vehicle Name</option> */}
                                        <option
                                          key={"delivery" + index}
                                          value={Typelist.vehicle_id}
                                        >
                                          {Typelist.vehicle_number}
                                        </option>
                                      </>
                                    );
                                  })}
                              </Form.Select>
                              <Form.Control.Feedback type="invalid">
                                Please select vehicle Name
                              </Form.Control.Feedback>
                            </div>
                          </div>
                          <div className="col-md-12 form_input_main">
                            <div className="innerSelectBox weekCounter">
                              <Form.Label className="common-labels">
                                {" "}
                                {t("Accessory Name")} <span>&#42;</span>{" "}
                              </Form.Label>
                              <Form.Control
                                required
                                type="text"
                                placeholder="Enter Accessory Name"
                                disabled={UserId ? true : false}
                                value={AddAccessry.accessory_name}
                                onChange={(e) => {
                                  setAddAccessry({
                                    ...AddAccessry,
                                    accessory_name: e.target.value,
                                  });
                                }}
                              />
                              <Form.Control.Feedback type="invalid">
                                Please Enter Accessory Name.
                              </Form.Control.Feedback>
                            </div>
                          </div>
                          <div className="col-md-12 form_input_main">
                            <div className="innerSelectBox weekCounter">
                              <Form.Label className="common-labels">
                                {" "}
                                {t("Quantity")} <span>&#42;</span>{" "}
                              </Form.Label>
                              <Form.Control
                                required
                                type="text"
                                placeholder="Enter Quantity"
                                disabled={UserId ? true : false}
                                value={AddAccessry.quantity}
                                onChange={(e) => {
                                  const inputValue = e.target.value.replace(/[^0-9]/g, '');
                                  setAddAccessry({
                                    ...AddAccessry,
                                    quantity:inputValue,
                                  });
                                }}
                              />
                              <Form.Control.Feedback type="invalid">
                                Please Enter Quantity.
                              </Form.Control.Feedback>
                            </div>
                          </div>
                          <div className="col-md-12 form_input_main">
                            <div className="innerSelectBox weekCounter">
                              <Form.Label className="common-labels">
                                {" "}
                                {t("Price")} <span>&#42;</span>{" "}
                              </Form.Label>
                              <Form.Control
                                required
                                type="text"
                                placeholder="Enter Price"
                                disabled={UserId ? true : false}
                                value={AddAccessry.price}
                                onChange={(e) => {
                                  const inputValue = e.target.value.replace(/[^0-9]/g, '');
                                  setAddAccessry({
                                    ...AddAccessry,
                                    price:inputValue,
                                  });
                                }}
                              />
                              <Form.Control.Feedback type="invalid">
                                Please Enter Price.
                              </Form.Control.Feedback>
                            </div>
                          </div>
                          <div className="col-md-12 form_input_main">
                            <div className="innerSelectBox weekCounter ">
                              <Form.Label className="common-labels">
                                {" "}
                                {t("Purchase From")} <span>&#42;</span>{" "}
                              </Form.Label>
                              <Form.Control
                                required
                                type="text"
                                placeholder="Enter The Name Of Supplier"
                                disabled={UserId ? true : false}
                                value={AddAccessry.purchase_from}
                                onChange={(e) => {
                                  setAddAccessry({
                                    ...AddAccessry,
                                    purchase_from: e.target.value,
                                  });
                                }}
                              />
                              <Form.Control.Feedback type="invalid">
                                Please Enter The Name Of Supplier.
                              </Form.Control.Feedback>
                            </div>
                          </div>
                          <div className="col-md-12  form_input_main">
                            {/* <div className="weekCounter"> */}
                            <Form.Label className="common-labels">
                                  {t("Purchase Date")} <span>&#42;</span>
                                </Form.Label>
                              <div className="innerSelectBox weekCounter datepicker-main">
                                 <CommonDatePicker
                                  setDate={setAddAccessry}
                                  data={AddAccessry}
                                  dateKey="purchase_date"
                                />

                                <Form.Control.Feedback type="invalid">
                                  Please Select Date.
                                </Form.Control.Feedback>
                              {/* </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* This section is for image addidtion in an array on every selection */}
                      <div className="imagesRightSec">
                        <div className="row">
                          <div className="col-md-12 form_input_main">
                            {/* <div className="innerSelectBox weekCounter">
                          <label className="common-labels">Invoice</label>
                    
                          <div className="savedInvoice">
                            <label>Example Document-Invoice.pdf</label>
                          </div>
                          <img src={Save} className="save" alt="" />
                       
                          <img src={Inspect} className="Inspect" alt="" />
                          <img src={Right} className="right" alt="" />
                          <label className="invalidText">
                            {t("Failed to upload")}
                          </label>
                          <label className="retry">Retry</label>
                        </div> */}
                          </div>
                          <div className="col-md-12 form_input_main">
                            {/* <div className="innerSelectBox weekCounter form_input_main">
                          <label className="common-labels">Invoice 2</label>
                 
                          <div className="savedInvoice">
                            <label>Example Document-Invoice.pdf</label>
                          </div>
                          <img src={Save} className="save" alt="" />
                      
                          <img src={Inspect} className="Inspect" alt="" />
                          <img src={Right} className="right" alt="" />
                          <label className="invalidText">
                            {t("Failed to upload")}
                          </label>
                          <label className="retry">Retry</label>
                        </div> */}
                            {/* Selected image preview here */}
                            <div className="previewImg">
                              <img
                                src={
                                  !AddAccessry.invoice
                                    ? Invoice
                                    : AddAccessry.invoice.length
                                    ? AddAccessry.invoice
                                    : AddAccessry.invoice &&
                                      URL.createObjectURL(AddAccessry.invoice)
                                }
                                className="InvoiceImg"
                                alt=""
                              />
                            </div>
                          </div>
                          <div className="col-md-12 form_input_main">
                            <div className="fileDropper">
                              <label htmlFor="" className="imageHolder">
                                {/* <input
                                  type="file"
                                  id="file"
                                  className="d-none"
                                /> */}
                                <div className="innerFlex">
                                  {/* <img
                                    src={uploadIcon}
                                    className="browseImg"
                                    alt=""
                                  />
                                  <p className="innerTxt">
                                    Drag & Drop Your File
                                  </p>
                                  <p className="innerTxt">Or</p> */}
                                  <label
                                    htmlFor="invoice"
                                    className="browseBtn"
                                  >
                                    <input
                                      // disabled={UserId? true : false}
                                      type="file"
                                      id="invoice"
                                      name="invoice"
                                      className="d-none"
                                      onChange={handleChange}
                                    />
                                    <p className="mt-1">Browse File</p>
                                  </label>
                                </div>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Two Bottom Buttons */}
                  <div className="d-flex justify-content-end align-items-center belowBtns">
                    <button
                      className="cx-btn-1"
                      type="button"
                      onClick={() => handleCancle()}
                    >
                      Cancel
                    </button>
                    <button className="cx-btn-2">
                      {UserId ? "Update" : "Submit"}
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          </>
        )}
      </motion.div>
    </>
  );
};

export default AddVehicleAccesory;
