import React, { useContext, useRef, useState } from "react";
import logo from "../../assets/images/Web-Application-Logo.svg";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";


const Register = () => {
    const [validated, setValidated] = useState(false);
    const ref = useRef();

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }

        setValidated(true);
    };
    function onChange(value) {
        console.log("Captcha value:", value);
    }
    return (
        <>
            {/* All the styles of this component styles are written in auth scss line number 470 */}
            <div id="registerFile">
                <div className="registerBox">
                    <div className="brandLogo">
                        <img src={logo} height="80" width="200" alt="" />
                    </div>
                    <h1 className="HeadTxt">Business Registration Form</h1>
                    <div className="auth-form">
                        <Form
                            noValidate
                            validated={validated}
                            onSubmit={handleSubmit}
                        >
                            <div className="row">
                                <div className="form_input_main">
                                    <Form.Label className="common-labels">
                                        Your Business Name <span className="red-star">*</span>
                                    </Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        placeholder="Enter your Business Name here..."
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please Enter your Business Name.
                                    </Form.Control.Feedback>
                                </div>

                                <div className="col-lg-6 form_input_main">
                                    <Form.Label className="common-labels">
                                        Address <span className="red-star">*</span>
                                    </Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        rows={5}
                                        placeholder="Address"
                                        required
                                        className="text-area"
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please Enter Address.
                                    </Form.Control.Feedback>
                                </div>
                                <div className="col-lg-6 ">
                                    <div className="form_input_main">
                                        <Form.Label className="common-labels">
                                            City <span className="red-star">*</span>
                                        </Form.Label>
                                        <Form.Control
                                            required
                                            type="text"
                                            placeholder="Country"
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Country.
                                        </Form.Control.Feedback>
                                    </div>
                                    <div className=" form_input_main select-group">
                                        <Form.Label className="common-labels">
                                            Country <span className="red-star">*</span>
                                        </Form.Label>
                                        <Form.Select
                                            required
                                            as="select"
                                            type="select"
                                            name="Speed_limit"
                                        >
                                            <option value="">Select Country</option>
                                            <option value="50">India</option>
                                            <option value="100">Canada</option>
                                            <option value="150">America</option>
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Enter you Email ID / Mobile Number.
                                        </Form.Control.Feedback>
                                    </div>
                                </div>

                                <div className="col-md-6 form_input_main">
                                    <Form.Label className="common-labels">
                                        Email ID <span className="red-star">*</span>
                                    </Form.Label>
                                    <Form.Control
                                        required
                                        type="email"
                                        placeholder="Enter you Email ID "
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please Enter Email.
                                    </Form.Control.Feedback>
                                </div>
                                <div className="col-md-6 form_input_main">
                                    <Form.Label className="common-labels">
                                        Password <span className="red-star">*</span>
                                    </Form.Label>
                                    <Form.Control
                                        required
                                        type="password"
                                        placeholder="Enter you Email ID "
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please Enter Password.
                                    </Form.Control.Feedback>
                                </div>

                                <div className="col-md-6 form_input_main">
                                    <Form.Label className="common-labels">
                                        Contact Number <span className="red-star">*</span>
                                    </Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        placeholder="Enter you Email ID "
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please Enter your Contact Number.
                                    </Form.Control.Feedback>
                                </div>

                                <div className="col-md-6 form_input_main select-group">
                                    <Form.Label className="common-labels">
                                        Time Zone. <span className="red-star">*</span>
                                    </Form.Label>
                                    <Form.Select
                                        required
                                        as="select"
                                        type="select"
                                        name="Speed_limit"
                                    >
                                        <option value="">Select Timezone</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                        <option value="150">150</option>
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        Please Select Timezone.
                                    </Form.Control.Feedback>
                                </div>

                                <div className="col-md-6 form_input_main select-group">
                                    <Form.Label className="common-labels">
                                        Customer Category.
                                    </Form.Label>
                                    <Form.Select
                                        required
                                        as="select"
                                        type="select"
                                        name="Speed_limit"
                                    >
                                        <option value="">
                                            Select Your Category of Business
                                        </option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                        <option value="150">150</option>
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        Please Select Your Category of Business.
                                    </Form.Control.Feedback>
                                </div>

                                <div className="col-md-6 form_input_main select-group">
                                    <Form.Label className="common-labels">
                                        Customer Organization Type.
                                    </Form.Label>
                                    <Form.Select
                                        required
                                        as="select"
                                        type="select"
                                        name="Speed_limit"
                                    >
                                        <option value="">Select Organization</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                        <option value="150">150</option>
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        Please Select Organization.
                                    </Form.Control.Feedback>
                                </div>

                                <div className="col-md-6 form_input_main select-group">
                                    <Form.Label className="common-labels">
                                        Customer Business Domain name.
                                    </Form.Label>
                                    <Form.Select
                                        required
                                        as="select"
                                        type="select"
                                        name="Speed_limit"
                                    >
                                        <option value="">Select your Business Domain</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                        <option value="150">150</option>
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        Please Select your Business Domain.
                                    </Form.Control.Feedback>
                                </div>

                                <div className="col-md-6 form_input_main">
                                    <Form.Label className="common-labels">
                                        Website <span className="red-star">*</span>
                                    </Form.Label>
                                    <Form.Control
                                        required
                                        type="email"
                                        placeholder="Enter URL of your website... "
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please URL of your website.
                                    </Form.Control.Feedback>
                                </div>
                                <div className="col-md-3 form_input_main">
                                    <Form.Label className="common-labels">
                                        captcha <span className="red-star">*</span>
                                    </Form.Label>
                                    <div className="capthaParent">
                                        <ReCAPTCHA
                                            ref={ref}
                                            sitekey="Your client site key"
                                            onChange={onChange}
                                            style={{ width: '100%', height: '100%' }}
                                        />
                                    </div>
                                    <Form.Control.Feedback type="invalid">
                                        Please URL of your website.
                                    </Form.Control.Feedback>
                                </div>
                                <div className="col-md-3 form_input_main">
                                    <Form.Label className="common-labels">
                                        {/* captcha <span className="red-star">*</span> */}
                                    </Form.Label>
                                    <Form.Control
                                        required
                                        className="mt-2"
                                        placeholder="Type Captcha Here"
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please URL of your website.
                                    </Form.Control.Feedback>
                                </div>
                            </div>

                            <div className="btn-auth">
                                <Link to="/RegistrationLocation">
                                    <button type="submit" className="filled-btn">
                                        Save & Proceed
                                    </button>
                                </Link>

                                <div className="link-style">
                                    <Link to="#">2023 @ Vehicle Tracking</Link>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Register
