import { React, useContext, useEffect, useState } from 'react'
import { AppContext } from '../../../context/AppContext'
import DDlogo from '../../../assets/images/smallDD.svg'
import Calendar from '../../../assets/images/calendar.svg'
import DatePicker from 'react-datepicker'
import { Link } from 'react-router-dom'
import Delete from '../../../assets/images/delete.svg'
import View from '../../../assets/images/Group.svg'
import EditIc from '../../../assets/images/ic-edit.svg'
import { Col, Modal, Nav, OverlayTrigger, Tooltip } from 'react-bootstrap'
import SideIc from '../../../assets/images/sideBar.svg'
import export_icon from '../../../assets/images/export_icon.svg'
import Form from 'react-bootstrap/Form'
import { Dropdown, Tab, Tabs } from 'react-bootstrap'
import { motion } from 'framer-motion'
import Pagenation from '../../../sharedComponent/Pagenation'
import { useTranslation } from 'react-i18next'
import { simplePostCall } from '../../../api/ApiServices'
import ApiConfig from '../../../api/ApiConfig'
import NoDataComp from '../../../sharedComponent/NoDataComp'
import NoMoreDataComp from '../../../sharedComponent/NoMoreDataComp'
import { DateDDMMYYYY } from '../../../sharedComponent/common'
import { useSelector } from 'react-redux'
import Loader from '../../../sharedComponent/Loader'
const VehicleMaintenance = () => {
  const accessRights = useSelector(state => state.auth.accessRights)
  // accessRights && accessRights.rights_role
  const userRole = "no Role"

  const { sidebar, setSidebar, Dark, setDark,recordsPerPage ,loading, setLoading} = useContext(AppContext)
  const [startDate, setStartDate] = useState(new Date())
  const [deleteModal, setDeleteModal] = useState(false)
  const [currentTab, setCurrentTab] = useState('0')
  const [VehicleName, setVehicleName] = useState('')
  const [VehicleServiceDate, setVehicleServiceDate] = useState('')
  const [VehicleKilometers, setVehicleKilometers] = useState('')
  const [MaintenaceStatus, setMaintenaceStatus] = useState('all')
  const [totalActive, setTotalActive] = useState(0)
  const [Maintenacepage, setMaintenacePage] = useState(1)
 const [last_page, setlast_page] = useState(false)
  const [vehicleMaintenaceList, setvehicleMaintenaceList] = useState([])

  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 }
  }
  const { t, i18n } = useTranslation()

  const renderTooltipForEdit = props => (
    <Tooltip id='button-tooltip' {...props}>
      Edit
    </Tooltip>
  )
  const renderTooltipForView = props => (
    <Tooltip id='button-tooltip' {...props}>
      View
    </Tooltip>
  )

  const handleSelectAll = () => {
    setCurrentTab("0")
    setMaintenaceStatus('all')
    geMaintenance(1, 'all')

    setMaintenacePage(1)
    setVehicleName('')
    setVehicleServiceDate('')
    setVehicleKilometers('')
  }
  const handleSelectDuse = () => {
    setCurrentTab("1")
    setMaintenaceStatus('dues')
    geMaintenance(1, 'dues')

    setMaintenacePage(1)
    setVehicleName('')
    setVehicleServiceDate('')
    setVehicleKilometers('')
  }
  const handleSelectUpcoming = () => {
    setCurrentTab("2")
    setMaintenaceStatus('upcoming')
    geMaintenance(1, 'upcoming')

    setMaintenacePage(1)
    setVehicleName('')
    setVehicleServiceDate('')
    setVehicleKilometers('')
  }

  const handleSelectHistory = () => {
    setCurrentTab("3")
    setMaintenaceStatus('history')
    geMaintenance(1, 'history')
    setMaintenacePage(1)
    setVehicleName('')
    setVehicleServiceDate('')
    setVehicleKilometers('')
  }

  useEffect(() => {
    geMaintenance(Maintenacepage, 'all')

    // geATranspoertAllList(page);
  }, [VehicleName, VehicleServiceDate, VehicleKilometers])

  function geMaintenance (currentPage, status) {
   currentPage==1 && setLoading(true);
  let newRequestBody = JSON.stringify({
      type: status,
      vehicle_name: VehicleName,
      kilometers: VehicleKilometers,
      service_date: VehicleServiceDate,
      next_service_date: VehicleServiceDate,
      page: currentPage,
      page_limit:recordsPerPage
    })
    simplePostCall(ApiConfig.MAINTENANCE_LIST, newRequestBody)
      .then(data => {
        setLoading(false)
        if (data.result) {
          // setTotalPages(data.total)
          setTotalActive(data.total_count)

          setlast_page(data.last_page)
          let FilterData = data.data ? data.data : []
          let TransportData = FilterData.map((eachteam, index) => {
            return {
              ...eachteam,
              user_profile_pic: eachteam.user_profile_pic || '',
              user_name: eachteam.user_name || '',
              user_email: eachteam.user_email || ''
            }
          })

          if (currentPage === 1 || currentPage === 0) {
            setvehicleMaintenaceList(data.data)
          } else {
            setvehicleMaintenaceList([
              ...vehicleMaintenaceList,
              ...TransportData
            ])
          }
        } else {
          setvehicleMaintenaceList(0)
          setTotalActive(data.total_count)

          setlast_page(false)
        }
      })
      .catch(err => {
        console.log('err', err)
      })
  }
  function onPageLoad () {
    setMaintenacePage(Maintenacepage + 1)
    geMaintenance(Maintenacepage + 1, 'all')
  }

  function onPageLoadduse () {
    setMaintenacePage(Maintenacepage + 1)
    geMaintenance(Maintenacepage + 1, 'dues')
  }

  function onPageLoadUpcoming () {
    setMaintenacePage(Maintenacepage + 1)
    geMaintenance(Maintenacepage + 1, 'upcoming')
  }
  function onPageLoadHistery () {
    setMaintenacePage(Maintenacepage + 1)
    geMaintenance(Maintenacepage + 1, 'history')
  }
  return (
    <>
      <motion.div
        className={sidebar ? 'taskMain ' : 'cx-active taskMain'}
        id='cx-main'
        variants={aninations}
        initial='initial'
        animate='animate'
        exit='exit'
        transition={{ duration: 0.1 }}
      >
        <div id='cx-wrapper' className='Fleet_Maintenance'>
          {/* Top inputs for instatnt search */}
          <div className='displayFlexInp'>
            <div className='innerSelectBox weekCounter selectForm form_input_main'>
              <input
                type='text'
                className='form-control'
                placeholder='Vehicle Name'
                value={VehicleName}
                onChange={e => {
                  setVehicleName(e.target.value)
                  setMaintenacePage(1)
                }}
              />
            </div>
            <div className='innerSelectBox weekCounter selectForm form_input_main datepicker-main'>
              {/* <DatePicker
                selected={startDate}
                placeholderText="Due Date"
                onChange={(date) => setStartDate(date)}
                className="form-control innerCust"
              /> */}
              <input
                class='datepicker-here form-control digits'
                type='date'
                data-language='en'
                placeholder='MM-DD-YYYY'
                data-position='top left'
                name='submission_date'
                value={VehicleServiceDate}
                // onChange={(date) => setStartDate(date)}
                onChange={e => setVehicleServiceDate(e.target.value)}
              />
            </div>
            <div className='innerSelectBox weekCounter selectForm form_input_main'>
              <Form.Control
                required
                type='text'
                placeholder='Kilometers'
                className='innerCust'
                value={VehicleKilometers}
                onChange={e => {
                  const inputValue = e.target.value.replace(/[^0-9]/g, '');
                  setVehicleKilometers(inputValue)
                  setMaintenacePage(1)
                }}
              />
            </div>

            <div className='innerSelectBox weekCounter selectForm form_input_main'>
              {userRole === 'customer' ||
              (accessRights &&
                accessRights?.rights_manage_vehicle_maintainance) ? (
                <Link to='/AddVehicleMaintenance' className='AddAccidentLink'>
                  <button className='innerCust'>+ {t('Add New')}</button>
                </Link>
              ) : null}
            </div>
            {/* <div className="headerDivIc form_input_main">
              <Link to="#">
                <img src={SideIc} alt="" />
              </Link>
            </div> */}
            <div className='headerDivIc form_input_main'>
              <Link to='#'>
                <img src={export_icon} alt='' />
              </Link>
            </div>
          </div>

          {/* Vehicle table */}

          {loading ? (
          <Loader />
        ) : (
          <>
          <div className='Vehcle-main-tabs' id='Vehcle-main-tabs'>
            <div className='main-master-wrapper mb-0 inner-tabs-section'>
              <div id='scroll_insideThe_Padding_tabel'>
                <div className='VA_table'>
                  <Tab.Container
                    id='left-tabs-example'
                    className='td-tab-wrapper'
                    defaultActiveKey='0'
                   activeKey={currentTab} 
                  >
                    <Nav
                      variant='pills'
                      className='td-nav'
                      id='InnerTabNew_Foure'
                       onSelect={selectedKey => setCurrentTab(`${selectedKey}`)}
                    >
                      <Nav.Item className='td-tab'>
                        <Nav.Link
                          className='td-link'
                          eventKey='0'
                          onClick={handleSelectAll}
                        >
                          All
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item className='td-tab'>
                        <Nav.Link
                          className='td-link'
                          eventKey='1'
                          onClick={handleSelectDuse}
                        >
                          Dues
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item className='td-tab'>
                        <Nav.Link
                          className='td-link'
                          eventKey='2'
                          onClick={handleSelectUpcoming}
                        >
                          Upcoming
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item className='td-tab'>
                        <Nav.Link
                          className='td-link'
                          eventKey='3'
                          onClick={handleSelectHistory}
                        >
                          History
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Col sm={12} className=''>
                      <Tab.Content>
                        <Tab.Pane eventKey='0'>
                          {/* Vehicle table */}
                          <div className='main-master-wrapper'>
                            <div
                              id='scroll_insideThe_Padding_tabel'
                              onScroll={e => {
                                const bottom =
                                  e.target.scrollHeight - e.target.scrollTop ===
                                  e.target.clientHeight

                                if (bottom && !last_page) onPageLoad()
                              }}
                            >
                              <div className='VA_table'>
                                <table className='table tableAdmin'>
                                  <thead className='tableHead'>
                                    <tr>
                                      <th>{t('Sr.no')}</th>
                                      <th>{t('Vehicle Name')}</th>
                                      <th>{t('Service Date')}</th>
                                      <th>{t('Kilometers Driven')}</th>
                                      <th>{t('Due Date')}</th>
                                      <th>{t('Service Cost')}</th>
                                      <th>{t('Action')}</th>
                                    </tr>
                                  </thead>
                                  {vehicleMaintenaceList &&
                                  vehicleMaintenaceList.length > 0 ? (
                                    vehicleMaintenaceList.map(
                                      (itemlist, index) => {
                                        return (
                                          <tbody className='tableBody'>
                                            <tr>
                                              <td>{index + 1}</td>
                                              <td>{itemlist.vehicle_number}</td>
                                              <td>
                                                {DateDDMMYYYY(
                                                  itemlist.maintainance_service_date
                                                )}
                                              </td>
                                              <td>
                                                {
                                                  itemlist.maintainance_kilometers_driven
                                                }
                                              </td>
                                              <td>
                                                {DateDDMMYYYY(
                                                  itemlist.maintainance_next_service_date
                                                )}
                                              </td>
                                              <td>
                                                {itemlist.maintainance_expense ==
                                                  null &&
                                                itemlist.last_maintainance_expense
                                                  ? itemlist.last_maintainance_expense
                                                  : itemlist.maintainance_expense
                                                  ? itemlist.maintainance_expense
                                                  : 'Not completed'}
                                              </td>
                                              <td>
                                                <div className='innerFlex d-flex'>
                                                  <OverlayTrigger
                                                    placement='bottom'
                                                    delay={{
                                                      show: 250,
                                                      hide: 400
                                                    }}
                                                    overlay={
                                                      renderTooltipForView
                                                    }
                                                  >
                                                    <Link
                                                      to={
                                                        '/ViewVehicleMaintenance/' +
                                                        itemlist.maintainance_vehicle_id +
                                                        '/' +
                                                        itemlist.maintainance_task
                                                      }
                                                    >
                                                      <div className='inconsIn me-3'>
                                                        <img
                                                          src={View}
                                                          alt=''
                                                        />
                                                      </div>
                                                    </Link>
                                                  </OverlayTrigger>
                                                  {userRole === 'customer' ||
                                                  (accessRights &&
                                                    accessRights?.rights_manage_vehicle_maintainance) ? (
                                                    <OverlayTrigger
                                                      placement='bottom'
                                                      delay={{
                                                        show: 250,
                                                        hide: 400
                                                      }}
                                                      overlay={
                                                        renderTooltipForEdit
                                                      }
                                                    >
                                                      <Link
                                                        to={
                                                          '/AddVehicleMaintenance/' +
                                                          itemlist.maintainance_id
                                                        }
                                                      >
                                                        <div className='inconsIn me-3'>
                                                          <img
                                                            src={EditIc}
                                                            alt=''
                                                          />
                                                        </div>
                                                      </Link>
                                                    </OverlayTrigger>
                                                  ) : null}
                                                </div>
                                              </td>
                                            </tr>
                                          </tbody>
                                        )
                                      }
                                    )
                                  ) : (
                                    <></>
                                  )}
                                </table>
                                {/* {last_page === true ? <NoMoreDataComp /> : ''} */}
                                {vehicleMaintenaceList.length > 0 ? (
                                  <></>
                                ) : (
                                  <NoDataComp />
                                )}
                              </div>
                            </div>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey='1'>
                          {/* Vehicle table */}
                          <div className='main-master-wrapper'>
                            <div
                              id='scroll_insideThe_Padding_tabel'
                              onScroll={e => {
                                const bottom =
                                  e.target.scrollHeight - e.target.scrollTop ===
                                  e.target.clientHeight

                                if (bottom && !last_page) onPageLoadduse()
                              }}
                            >
                              <div className='VA_table'>
                                <table className='table tableAdmin'>
                                  <thead className='tableHead'>
                                    <tr>
                                      <th>{t('Sr.no')}</th>
                                      <th>{t('Vehicle Name')}</th>
                                      <th>{t('Service Date')}</th>
                                      <th>{t('Kilometers Driven')}</th>
                                      <th>{t('Due Date')}</th>
                                      <th>{t('Service Cost')}</th>
                                      <th>{t('Action')}</th>
                                    </tr>
                                  </thead>

                                  {vehicleMaintenaceList &&
                                  vehicleMaintenaceList.length > 0 ? (
                                    vehicleMaintenaceList.map(
                                      (itemlist, index) => {
                                        return (
                                          <tbody className='tableBody'>
                                            <tr>
                                              <td>{index + 1}</td>
                                              <td>{itemlist.vehicle_number}</td>
                                              <td>
                                                {DateDDMMYYYY(
                                                  itemlist.maintainance_service_date
                                                )}
                                              </td>
                                              <td>
                                                {
                                                  itemlist.maintainance_kilometers_driven
                                                }
                                              </td>
                                              <td>
                                                {DateDDMMYYYY(
                                                  itemlist?.maintainance_next_service_date
                                                )}
                                              </td>
                                              <td>
                                                {itemlist.maintainance_expense ==
                                                  null &&
                                                itemlist.last_maintainance_expense
                                                  ? itemlist.last_maintainance_expense
                                                  : itemlist.maintainance_expense
                                                  ? itemlist.maintainance_expense
                                                  : 'Not completed'}
                                              </td>
                                              <td>
                                                <div className='innerFlex d-flex'>
                                                  <OverlayTrigger
                                                    placement='bottom'
                                                    delay={{
                                                      show: 250,
                                                      hide: 400
                                                    }}
                                                    overlay={
                                                      renderTooltipForView
                                                    }
                                                  >
                                                    <Link
                                                      to={
                                                        '/ViewVehicleMaintenance/' +
                                                        itemlist?.maintainance_vehicle_id +
                                                        '/'+
                                                        itemlist?.maintainance_task?.trim()
                                                      }
                                                    >
                                                      <div className='inconsIn me-3'>
                                                        <img
                                                          src={View}
                                                          alt=''
                                                        />
                                                      </div>
                                                    </Link>
                                                  </OverlayTrigger>
                                                  <OverlayTrigger
                                                    placement='bottom'
                                                    delay={{
                                                      show: 250,
                                                      hide: 400
                                                    }}
                                                    overlay={
                                                      renderTooltipForEdit
                                                    }
                                                  >
                                                    <Link
                                                      to={
                                                        '/AddVehicleMaintenance/' +
                                                        itemlist.maintainance_id
                                                      }
                                                    >
                                                      <div className='inconsIn me-3'>
                                                        <img
                                                          src={EditIc}
                                                          alt=''
                                                        />
                                                      </div>
                                                    </Link>
                                                  </OverlayTrigger>
                                                </div>
                                              </td>
                                            </tr>
                                          </tbody>
                                        )
                                      }
                                    )
                                  ) : (
                                    <></>
                                  )}
                                </table>
                                {/* {last_page === true ? <NoMoreDataComp /> : ''} */}
                                {vehicleMaintenaceList.length > 0 ? (
                                  <></>
                                ) : (
                                  <NoDataComp />
                                )}
                              </div>
                            </div>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey='2'>
                          {/* Vehicle table */}
                          <div className='main-master-wrapper'>
                            <div
                              id='scroll_insideThe_Padding_tabel'
                              onScroll={e => {
                                const bottom =
                                  e.target.scrollHeight - e.target.scrollTop ===
                                  e.target.clientHeight

                                if (bottom && !last_page) onPageLoadUpcoming()
                              }}
                            >
                              <div className='VA_table'>
                                <table className='table tableAdmin'>
                                  <thead className='tableHead'>
                                    <tr>
                                      <th>{t('Sr.no')}</th>
                                      <th>{t('Vehicle Name')}</th>
                                      <th>{t('Service Date')}</th>
                                      <th>{t('Kilometers Driven')}</th>
                                      <th>{t('Due Date')}</th>
                                      <th>{t('Service Cost')}</th>
                                      <th>{t('Action')}</th>
                                    </tr>
                                  </thead>
                                  {vehicleMaintenaceList &&
                                  vehicleMaintenaceList.length > 0 ? (
                                    vehicleMaintenaceList.map(
                                      (itemlist, index) => {
                                        return (
                                          <tbody className='tableBody'>
                                            <tr>
                                              <td>{index + 1}</td>
                                              <td>{itemlist.vehicle_number}</td>
                                              <td>
                                                {DateDDMMYYYY(
                                                  itemlist.maintainance_service_date
                                                )}
                                              </td>
                                              <td>
                                                {
                                                  itemlist.maintainance_kilometers_driven
                                                }
                                              </td>
                                              <td>
                                                {DateDDMMYYYY(
                                                  itemlist.maintainance_next_service_date
                                                )}
                                              </td>
                                              <td>
                                                {itemlist.maintainance_expense ==
                                                  null &&
                                                itemlist.last_maintainance_expense
                                                  ? itemlist.last_maintainance_expense
                                                  : itemlist.maintainance_expense
                                                  ? itemlist.maintainance_expense
                                                  : 'Not completed'}
                                              </td>
                                              <td>
                                                <div className='innerFlex d-flex'>
                                                  <OverlayTrigger
                                                    placement='bottom'
                                                    delay={{
                                                      show: 250,
                                                      hide: 400
                                                    }}
                                                    overlay={
                                                      renderTooltipForView
                                                    }
                                                  >
                                                    <Link
                                                      to={
                                                        '/ViewVehicleMaintenance/' +
                                                        itemlist.maintainance_vehicle_id +
                                                        '/' +
                                                        itemlist?.maintainance_task?.trim()
                                                      }
                                                    >
                                                      <div className='inconsIn me-3'>
                                                        <img
                                                          src={View}
                                                          alt=''
                                                        />
                                                      </div>
                                                    </Link>
                                                  </OverlayTrigger>
                                                  <OverlayTrigger
                                                    placement='bottom'
                                                    delay={{
                                                      show: 250,
                                                      hide: 400
                                                    }}
                                                    overlay={
                                                      renderTooltipForEdit
                                                    }
                                                  >
                                                    <Link
                                                      to={
                                                        '/AddVehicleMaintenance/' +
                                                        itemlist.maintainance_id
                                                      }
                                                    >
                                                      <div className='inconsIn me-3'>
                                                        <img
                                                          src={EditIc}
                                                          alt=''
                                                        />
                                                      </div>
                                                    </Link>
                                                  </OverlayTrigger>
                                                </div>
                                              </td>
                                            </tr>
                                          </tbody>
                                        )
                                      }
                                    )
                                  ) : (
                                    <></>
                                  )}
                                </table>
                                {/* {last_page === true ? <NoMoreDataComp /> : ''} */}
                                {vehicleMaintenaceList.length > 0 ? (
                                  <></>
                                ) : (
                                  <NoDataComp />
                                )}
                              </div>
                            </div>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey='3'>
                          {/* Vehicle table */}
                          <div className='main-master-wrapper'>
                            <div
                              id='scroll_insideThe_Padding_tabel'
                              onScroll={e => {
                                const bottom =
                                  e.target.scrollHeight - e.target.scrollTop ===
                                  e.target.clientHeight

                                if (bottom && !last_page) onPageLoadHistery()
                              }}
                            >
                              <div className='VA_table'>
                                <table className='table tableAdmin'>
                                  <thead className='tableHead'>
                                    <tr>
                                      <th>{t('Sr.no')}</th>
                                      <th>{t('Vehicle Name')}</th>
                                      <th>{t('Service Date')}</th>
                                      <th>{t('Kilometers Driven')}</th>
                                      <th>{t('Due Date')}</th>
                                      <th>{t('Last Service Cost')}</th>
                                      <th>{t('Action')}</th>
                                    </tr>
                                  </thead>
                                  {vehicleMaintenaceList &&
                                  vehicleMaintenaceList.length > 0 ? (
                                    vehicleMaintenaceList.map(
                                      (itemlist, index) => {
                                        return (
                                          <tbody className='tableBody'>
                                            <tr>
                                              <td>{index + 1}</td>
                                              <td>{itemlist.vehicle_number}</td>
                                              <td>
                                                {DateDDMMYYYY(
                                                  itemlist.maintainance_service_date
                                                )}
                                              </td>
                                              <td>
                                                {
                                                  itemlist.maintainance_kilometers_driven
                                                }
                                              </td>
                                              <td>
                                                {DateDDMMYYYY(
                                                  itemlist?.maintainance_next_service_date
                                                )}
                                              </td>
                                              <td>
                                                {itemlist.maintainance_expense ==
                                                  null &&
                                                itemlist.last_maintainance_expense
                                                  ? itemlist.last_maintainance_expense
                                                  : itemlist.maintainance_expense
                                                  ? itemlist.maintainance_expense
                                                  : 'Not completed'}
                                              </td>
                                              <td>
                                                <div className='innerFlex d-flex'>
                                                  <OverlayTrigger
                                                    placement='bottom'
                                                    delay={{
                                                      show: 250,
                                                      hide: 400
                                                    }}
                                                    overlay={
                                                      renderTooltipForView
                                                    }
                                                  >
                                                    <Link
                                                      to={
                                                        '/ViewVehicleMaintenance/'+
                                                        itemlist?.maintainance_vehicle_id
                                                      }
                                                    >
                                                      <div className='inconsIn me-3'>
                                                        <img
                                                          src={View}
                                                          alt=''
                                                        />
                                                      </div>
                                                    </Link>
                                                  </OverlayTrigger>
                                                  <OverlayTrigger
                                                    placement='bottom'
                                                    delay={{
                                                      show: 250,
                                                      hide: 400
                                                    }}
                                                    overlay={
                                                      renderTooltipForEdit
                                                    }
                                                  >
                                                    <Link
                                                      to={
                                                        '/AddVehicleMaintenance/' +
                                                        itemlist.maintainance_id
                                                      }
                                                    >
                                                      <div className='inconsIn me-3'>
                                                        <img
                                                          src={EditIc}
                                                          alt=''
                                                        />
                                                      </div>
                                                    </Link>
                                                  </OverlayTrigger>
                                                </div>
                                              </td>
                                            </tr>
                                          </tbody>
                                        )
                                      }
                                    )
                                  ) : (
                                    <></>
                                  )}
                                </table>
                                {/* {last_page === true ? <NoMoreDataComp /> : ''} */}
                                {vehicleMaintenaceList.length > 0 ? (
                                  <></>
                                ) : (
                                  <NoDataComp />
                                )}
                              </div>
                            </div>
                          </div>
                        </Tab.Pane>
                      </Tab.Content>
                    </Col>
                  </Tab.Container>
                </div>
              </div>
         { vehicleMaintenaceList?.length>0 &&    <Pagenation
                length={vehicleMaintenaceList.length}
                total={totalActive}
              />}
            </div>
          </div>
          </>
        )}

        </div>
      </motion.div>

      {/* Delete Modal Start */}
      <Modal
        show={deleteModal}
        onHide={() => setDeleteModal(false)}
        centered
        className='common-model'
      >
        <Modal.Header closeButton>
          <Modal.Title>{t('Delete Fleet Maintenance')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t('Are you sure you want to Delete this Fleet Maintenance')} ?
        </Modal.Body>
        <Modal.Footer className='pop-up-modal-footer'>
          <div class='btn-wrapper'>
            <button className='cx-btn-1' onClick={() => setDeleteModal(false)}>
              {t('Cancel')}
            </button>
            <button className='cx-btn-2' onClick={() => setDeleteModal(false)}>
              {t('Yes')}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
      {/* Delete Modal End */}
    </>
  )
}

export default VehicleMaintenance
