// Usama 09-02-2023
import React, { useContext, useState, useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Carousel, Col, Dropdown, Modal, Tab, Tabs } from "react-bootstrap";
import { AppContext } from "../../context/AppContext";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import Export from "../../assets/images/ic-Export.svg";
import Import from "../../assets/images/ic-Import.svg";
import { motion } from "framer-motion";
import Nav from "react-bootstrap/Nav";
import Cat_ye_car from "../../assets/images/Catagiry_yellow_car.svg";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { PostCallWithErrorResponse, simplePostCall } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import Loader from "../../sharedComponent/Loader";
import Pagenation from "../../sharedComponent/Pagenation";

const OfferVehicleMarketPlace = () => {
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [view, setView] = useState(false);
  const [vCars, setVCars] = useState(false);
  const [vBike, setVBike] = useState(false);
  const [state, setState] = useState(false);
  const [loader, setLoader] = useState(false);
  const [vehicleList, setVehicleList] = useState([]);
  const [selectedObj, setSelectedObj] = useState([]);
 const [last_page, setlast_page] = useState(false);
  const [total_count, setTotal_count] = useState(null);
  const [allVehiCoun, setallVehiCount] = useState(0);
  const [page, setPage] = useState(1);
  const [bottom, setBottom] = useState("");
  const [tabURL, setTabURL] = useState(ApiConfig.GET_VEHICLE_LIST);
  const [isChecked, setIsChecked] = useState(false);
  const [checkedItems, setCheckedItems] = useState({});

  // Function to handle checkbox toggle
  const handleCheckboxChange = (index, data) => {
    // setSelectedObj([...selectedObj, data]);
    setCheckedItems((prevCheckedItems) => ({
      ...prevCheckedItems,
      [index]: !prevCheckedItems[index],
    }));
    if (selectedObj.includes(data)) {
      setSelectedObj((prevSelectedItems) =>
        prevSelectedItems.filter((item) => item !== data)
      );
    } else {
      // If it's not checked, add it to the array
      setSelectedObj((prevSelectedItems) => [...prevSelectedItems, data]);
    }
  };

  // removeFromSelected 
  const removeFromSelected = (data) => {
    // selectedObj.filter((item, index))
  }
  const showView = () => {
    setView(true);
  };
  const hideView = () => {
    setView(false);
  };
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  //category
  const [category, setCategory] = useState(false);
  const DeleteCategory = () => setShow(false);
  const showCategory = () => setShow(true);
  //group
  const [group, setGroup] = useState(false);
  const DeleteGroup = () => setShow(false);
  const showGroup = () => setShow(true);

  const { sidebar, setSidebar, Dark, setDark } = useContext(AppContext);


  const getVehiclesList = (page) => {
    setLoader(true);
    let payload = {
      page: page,
      vehicle_capacity: "",
      vehicle_category: "",
      vehicle_name: "",
      vehicle_type: "allvehicle"
    };

    simplePostCall(tabURL, JSON.stringify(payload))
      .then((res) => {
        if (res.result === true) {

          setVehicleList([...vehicleList, ...res?.data])
          setallVehiCount(res);
          setlast_page(res?.last_page ? res?.last_page : "");
          setTotal_count(res?.total_count ? res?.total_count : "");
          setLoader(false);
        }
      }).catch((error) => {
        console.log(error)
      })
  };


  useEffect(() => {
    AOS.init({ duration: 2000 });
    getVehiclesList()
  }, []);

  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
  };


  const [Immobilization, setImmobilization] = useState(true);
  const [Immobilization1, setImmobilization1] = useState(true);
  const [Tempr, setTempr] = useState(true);
  const [Fuel, setFuel] = useState(true);
  const [ibtn, setIbtn] = useState(true);
  const [seat, setSeat] = useState(true);
  const [echo, setEcho] = useState(true);
  const [ivms, setIVMS] = useState(true);
  const [card, setCard] = useState(true);
  const [speed, setSpeed] = useState(true);
  const [cresh, setCresh] = useState(true);
  const [exicess, setExicess] = useState(true);
  const [towing, setTowing] = useState(true);
  const [plug, setPlug] = useState(true);

  return (
    <motion.div
      variants={aninations}
      initial="initial"
      animate="animate"
      exit="exit"
      transition={{ duration: 0.1 }}
      className={sidebar ? "taskMain " : "cx-active taskMain"}
      id="cx-main"
    >
      <div id="cx-wrapper" className="Vehicle_Main">
        <div
          className="Vehcle-main-tabs cx-marketPlace-main"
          id="cx-marketPlace"
        >
          <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            <Nav variant="pills" id="newTabMai" className="tob_nav_pills">
              <Nav.Item>
                <Nav.Link eventKey="first">{t(`All Vehicles (${allVehiCoun?.total_count == null || undefined ? 0 : (allVehiCoun?.total_count)})`)}</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="second">{t(`Offered (${selectedObj.length ? selectedObj.length : 0})`)}</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="three">
                  {t("Assigned Vehicles (15)")}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="four">{t("Available (35)")}</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="Five">{t("Blocked (0)")}</Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="first">
                <div className="main-master-wrapper mb-0 inner-tabs-section overflow-hidden">
                  <div id="scroll_insideThe_Padding">
                    <div className="all-vehicle-main">
                      <div className="all-vehical-head row vehicle-top-inputs">
                        <div className="input-section-wrapper">
                          <div className="row">
                            <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Vehicle Name,No., Reg. No, IMEI..."
                              />
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Vehicle Category"
                              />
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Vehicle Capacity"
                              />
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12 mb-3 innerSelectBox weekcounder">
                              <select
                                required
                                className="form-select"
                                aria-label="Default select example"
                                placeholder="Transportation Type"
                              >
                                <option value="">Transportation Type</option>
                                <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="right-export-btn-section-wrapper">
                          <div className="c-pointer me-2">
                            <img src={Export} alt="" />
                          </div>
                          <div className="c-pointer">
                            <img src={Import} alt="" />
                          </div>
                        </div>
                      </div>
                      {
                        vehicleList && vehicleList?.length > 0 ?
                          <div className="yauto" id="arrange-paading"
                            onScroll={(e) => {
                              setBottom(e ? true : false)
                              const bottom =
                                e.target.scrollHeight - e.target.scrollTop ===
                                e.target.clientHeight


                              if (bottom && !last_page) {
                                setPage(page + 1);
                                getVehiclesList(page + 1)
                                // vehicleAllList(tabURL, page + 1);


                              }

                            }}>
                            <div className="row main-cards-wrapper gx-3">
                              {
                                vehicleList.map((itemvehicle, index) => {
                                  return (
                                    <div
                                      className={
                                        sidebar
                                          ? "col-lg-6 col-md-6"
                                          : "col-lg-4 col-md-6"
                                      }
                                      key={index}
                                    >
                                      <div className={"common-cat-vehical-card-inner"}>
                                        <div className="cat-body w-100">
                                          <div
                                            className="head-with-img"
                                            id="head-with-img-switch"
                                          >
                                            <div id="head-with-img">
                                              <div className="left img">
                                                <img src={Cat_ye_car} alt="" />
                                              </div>
                                              <div className="right v-name cat-body-discription">
                                                <label htmlFor="">
                                                  {t("Vehicle Name")}
                                                </label>
                                                <p>Volvo Transport</p>
                                              </div>
                                            </div>

                                            <div
                                              className="form-check form-switch"
                                              id="custom_switch"
                                            >
                                              {/* <label htmlFor={`checkbox-${index}`}>Hello</label> */}
                                              <input
                                                className="form-check-input"
                                                type="checkbox"
                                                id={`checkbox-${index}`}
                                                checked={checkedItems[index] || false}
                                                // checked={itemvehicle.isChecked === true ? true : false}
                                                onChange={() => {
                                                  handleCheckboxChange(index, itemvehicle)
                                                }}
                                              />
                                            </div>
                                          </div>
                                          <div className="row">
                                            <div className="col-lg-6 cat-body-discription mt-2">
                                              <label htmlFor="">
                                                {t("Vehicle Number")}
                                              </label>
                                              <p>{itemvehicle.vehicle_number ? itemvehicle.vehicle_number : "No Data"}</p>
                                            </div>
                                            <div className="col-lg-6 cat-body-discription mt-2">
                                              <label htmlFor="">{t("Driver Name")}</label>
                                              <p>Mr. John Doe</p>
                                            </div>
                                            <div className="col-lg-6 cat-body-discription mt-2">
                                              <label htmlFor="">{t("IMEI No.")}</label>
                                              <p>{itemvehicle?.vehicle_imei ? itemvehicle?.vehicle_imei : "No Data"}</p>
                                            </div>
                                            <div className="col-lg-6 cat-body-discription mt-2">
                                              <label htmlFor="">
                                                {t("Vehicle Type")}
                                              </label>
                                              <p>{itemvehicle.vehicle_type ? itemvehicle.vehicle_type : "No Data"}</p>
                                            </div>
                                            <div className="col-lg-6 cat-body-discription mt-2">
                                              <label htmlFor="">
                                                {t("Vehicle Capacity")}
                                              </label>
                                              <p>{itemvehicle.vehicle_type_capacity ? itemvehicle.vehicle_type_capacity : "No Data"}</p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )
                                })
                              }

                            </div>
                          </div>
                          : <Loader />
                      }
                    </div>
                  </div>
                  {/* <p className="reg-color mt-3"> */}
                  <Pagenation length={vehicleList?.length} total={total_count} />
                  {/* </p> */}
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="second">
                <div className="main-master-wrapper mb-0 inner-tabs-section overflow-hidden">
                  <div id="scroll_insideThe_Padding">
                    <div className="all-vehicle-main">
                      <div className="all-vehical-head row vehicle-top-inputs">
                        <div className="input-section-wrapper">
                          <div className="row">
                            <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Vehicle Name,No., Reg. No, IMEI..."
                              />
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Vehicle Category"
                              />
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Vehicle Capacity"
                              />
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12 mb-3 innerSelectBox weekcounder">
                              <select
                                required
                                className="form-select"
                                aria-label="Default select example"
                                placeholder="Transportation Type"
                              >
                                <option value="">Transportation Type</option>
                                <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="right-export-btn-section-wrapper">
                          <div className="c-pointer me-2">
                            <img src={Export} alt="" />
                          </div>
                          <div className="c-pointer">
                            <img src={Import} alt="" />
                          </div>
                        </div>
                      </div>

                      <div className="yauto" id="arrange-paading"
                        onScroll={(e) => {
                          setBottom(e ? true : false)
                          const bottom =
                            e.target.scrollHeight - e.target.scrollTop ===
                            e.target.clientHeight


                          if (bottom && !last_page) {
                            setPage(page + 1);
                            getVehiclesList(page + 1)
                            // vehicleAllList(tabURL, page + 1);


                          }

                        }}>
                        {
                          selectedObj && selectedObj.length > 0 ?
                            <>
                              <div className="row main-cards-wrapper gx-3">
                                {
                                  selectedObj.map((data, index) => {
                                    return (
                                      <div
                                        className={
                                          sidebar
                                            ? "col-lg-6 col-md-6"
                                            : "col-lg-4 col-md-6"
                                        }
                                      >
                                        <div className={"common-cat-vehical-card-inner"}>
                                          <div className="cat-body w-100">
                                            <div className="head-with-img">
                                              <div className="left img">
                                                <img src={Cat_ye_car} alt="" />
                                              </div>
                                              <div className="right v-name cat-body-discription">
                                                <label htmlFor="">
                                                  {t("Vehicle Name")}
                                                </label>
                                                <p>Volvo Transport</p>
                                              </div>
                                            </div>
                                            <div className="row">
                                              <div className="col-lg-6 cat-body-discription mt-2">
                                                <label htmlFor="">
                                                  {t("Vehicle Number")}
                                                </label>
                                                <p>{data.vehicle_number == null || undefined ? "No Data" : data.vehicle_number}</p>
                                              </div>
                                              <div className="col-lg-6 cat-body-discription mt-2">
                                                <label htmlFor="">{t("Driver Name")}</label>
                                                <p>Mr. John Doe</p>
                                              </div>
                                              <div className="col-lg-6 cat-body-discription mt-2">
                                                <label htmlFor="">{t("IMEI No.")}</label>
                                                <p>{data.vehicle_imei == null || undefined ? "No Data" : data.vehicle_imei}</p>
                                              </div>
                                              <div className="col-lg-6 cat-body-discription mt-2">
                                                <label htmlFor="">
                                                  {t("Vehicle Type")}
                                                </label>
                                                <p>{data.vehicle_type == null || undefined ? "No Data" : data.vehicle_type}</p>
                                              </div>
                                              <div className="col-lg-6 cat-body-discription mt-2">
                                                <label htmlFor="">
                                                  {t("Vehicle Capacity")}
                                                </label>
                                                <p>{data.vehicle_seat_capacity == null || undefined ? "No Data" : data.vehicle_seat_capacity}</p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    )
                                  })
                                }
                              </div>
                            </>
                            : <div className="d-flex justify-content-center align-items-center text-center h-100"><p className="text-danger fw-bold">No Vehicle Selected !...</p></div>
                        }
                      </div>
                    </div>
                  </div>
                  {
                    selectedObj && selectedObj.length > 0 ?
                      <Pagenation length={selectedObj.length} total={total_count} /> :
                      null
                  }
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="three">
                <div className="main-master-wrapper mb-0 inner-tabs-section overflow-hidden">
                  <div id="scroll_insideThe_Padding">
                    <div className="all-vehicle-main">
                      <div className="all-vehical-head row vehicle-top-inputs">
                        <div className="input-section-wrapper">
                          <div className="row">
                            <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Vehicle Name,No., Reg. No, IMEI..."
                              />
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Vehicle Category"
                              />
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Vehicle Capacity"
                              />
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12 mb-3 innerSelectBox weekcounder">
                              <select
                                required
                                className="form-select"
                                aria-label="Default select example"
                                placeholder="Transportation Type"
                              >
                                <option value="">Transportation Type</option>
                                <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="right-export-btn-section-wrapper">
                          <div className="c-pointer me-2">
                            <img src={Export} alt="" />
                          </div>
                          <div className="c-pointer">
                            <img src={Import} alt="" />
                          </div>
                        </div>
                      </div>

                      <div className="yauto" id="arrange-paading">
                        <div className="row main-cards-wrapper gx-3">
                          <div
                            className={
                              sidebar
                                ? "col-lg-6 col-md-6"
                                : "col-lg-4 col-md-6"
                            }
                          >
                            <div className={"common-cat-vehical-card-inner"}>
                              <div className="cat-body w-100">
                                <div className="head-with-img">
                                  <div className="left img">
                                    <img src={Cat_ye_car} alt="" />
                                  </div>
                                  <div className="right v-name cat-body-discription">
                                    <label htmlFor="">
                                      {t("Vehicle Name")}
                                    </label>
                                    <p>Volvo Transport</p>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">
                                      {t("Vehicle Number")}
                                    </label>
                                    <p>MH-12-5022</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">{t("Driver Name")}</label>
                                    <p>Mr. John Doe</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">{t("IMEI No.")}</label>
                                    <p>1234567894561230</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">
                                      {t("Vehicle Type")}
                                    </label>
                                    <p>Truck</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">
                                      {t("Vehicle Capacity")}
                                    </label>
                                    <p>60</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className={
                              sidebar
                                ? "col-lg-6 col-md-6"
                                : "col-lg-4 col-md-6"
                            }
                          >
                            <div className={"common-cat-vehical-card-inner"}>
                              <div className="cat-body w-100">
                                <div className="head-with-img">
                                  <div className="left img">
                                    <img src={Cat_ye_car} alt="" />
                                  </div>
                                  <div className="right v-name cat-body-discription">
                                    <label htmlFor="">
                                      {t("Vehicle Name")}
                                    </label>
                                    <p>Volvo Transport</p>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">
                                      {t("Vehicle Number")}
                                    </label>
                                    <p>MH-12-5022</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">{t("Driver Name")}</label>
                                    <p>Mr. John Doe</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">{t("IMEI No.")}</label>
                                    <p>1234567894561230</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">
                                      {t("Vehicle Type")}
                                    </label>
                                    <p>Truck</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">
                                      {t("Vehicle Capacity")}
                                    </label>
                                    <p>60</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>{" "}
                          <div
                            className={
                              sidebar
                                ? "col-lg-6 col-md-6"
                                : "col-lg-4 col-md-6"
                            }
                          >
                            <div className={"common-cat-vehical-card-inner"}>
                              <div className="cat-body w-100">
                                <div className="head-with-img">
                                  <div className="left img">
                                    <img src={Cat_ye_car} alt="" />
                                  </div>
                                  <div className="right v-name cat-body-discription">
                                    <label htmlFor="">
                                      {t("Vehicle Name")}
                                    </label>
                                    <p>Volvo Transport</p>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">
                                      {t("Vehicle Number")}
                                    </label>
                                    <p>MH-12-5022</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">{t("Driver Name")}</label>
                                    <p>Mr. John Doe</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">{t("IMEI No.")}</label>
                                    <p>1234567894561230</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">
                                      {t("Vehicle Type")}
                                    </label>
                                    <p>Truck</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">
                                      {t("Vehicle Capacity")}
                                    </label>
                                    <p>60</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>{" "}
                          <div
                            className={
                              sidebar
                                ? "col-lg-6 col-md-6"
                                : "col-lg-4 col-md-6"
                            }
                          >
                            <div className={"common-cat-vehical-card-inner"}>
                              <div className="cat-body w-100">
                                <div className="head-with-img">
                                  <div className="left img">
                                    <img src={Cat_ye_car} alt="" />
                                  </div>
                                  <div className="right v-name cat-body-discription">
                                    <label htmlFor="">
                                      {t("Vehicle Name")}
                                    </label>
                                    <p>Volvo Transport</p>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">
                                      {t("Vehicle Number")}
                                    </label>
                                    <p>MH-12-5022</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">{t("Driver Name")}</label>
                                    <p>Mr. John Doe</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">{t("IMEI No.")}</label>
                                    <p>1234567894561230</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">
                                      {t("Vehicle Type")}
                                    </label>
                                    <p>Truck</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">
                                      {t("Vehicle Capacity")}
                                    </label>
                                    <p>60</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>{" "}
                          <div
                            className={
                              sidebar
                                ? "col-lg-6 col-md-6"
                                : "col-lg-4 col-md-6"
                            }
                          >
                            <div className={"common-cat-vehical-card-inner"}>
                              <div className="cat-body w-100">
                                <div className="head-with-img">
                                  <div className="left img">
                                    <img src={Cat_ye_car} alt="" />
                                  </div>
                                  <div className="right v-name cat-body-discription">
                                    <label htmlFor="">
                                      {t("Vehicle Name")}
                                    </label>
                                    <p>Volvo Transport</p>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">
                                      {t("Vehicle Number")}
                                    </label>
                                    <p>MH-12-5022</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">{t("Driver Name")}</label>
                                    <p>Mr. John Doe</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">{t("IMEI No.")}</label>
                                    <p>1234567894561230</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">
                                      {t("Vehicle Type")}
                                    </label>
                                    <p>Truck</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">
                                      {t("Vehicle Capacity")}
                                    </label>
                                    <p>60</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <p className="reg-color mt-3">
                    {t("Showing")} 1 - 10 {t("of")} 200
                  </p>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="four">
                <div className="main-master-wrapper mb-0 inner-tabs-section overflow-hidden">
                  <div id="scroll_insideThe_Padding">
                    <div className="all-vehicle-main">
                      <div className="all-vehical-head row vehicle-top-inputs">
                        <div className="input-section-wrapper">
                          <div className="row">
                            <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Vehicle Name,No., Reg. No, IMEI..."
                              />
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Vehicle Category"
                              />
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Vehicle Capacity"
                              />
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12 mb-3 innerSelectBox weekcounder">
                              <select
                                required
                                className="form-select"
                                aria-label="Default select example"
                                placeholder="Transportation Type"
                              >
                                <option value="">Transportation Type</option>
                                <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="right-export-btn-section-wrapper">
                          <div className="c-pointer me-2">
                            <img src={Export} alt="" />
                          </div>
                          <div className="c-pointer">
                            <img src={Import} alt="" />
                          </div>
                        </div>
                      </div>

                      <div className="yauto" id="arrange-paading">
                        <div className="row main-cards-wrapper gx-3">
                          <div
                            className={
                              sidebar
                                ? "col-lg-6 col-md-6"
                                : "col-lg-4 col-md-6"
                            }
                          >
                            <div className={"common-cat-vehical-card-inner"}>
                              <div className="cat-body w-100">
                                <div className="head-with-img">
                                  <div className="left img">
                                    <img src={Cat_ye_car} alt="" />
                                  </div>
                                  <div className="right v-name cat-body-discription">
                                    <label htmlFor="">
                                      {t("Vehicle Name")}
                                    </label>
                                    <p>Volvo Transport</p>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">
                                      {t("Vehicle Number")}
                                    </label>
                                    <p>MH-12-5022</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">{t("Driver Name")}</label>
                                    <p>Mr. John Doe</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">{t("IMEI No.")}</label>
                                    <p>1234567894561230</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">
                                      {t("Vehicle Type")}
                                    </label>
                                    <p>Truck</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">
                                      {t("Vehicle Capacity")}
                                    </label>
                                    <p>60</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className={
                              sidebar
                                ? "col-lg-6 col-md-6"
                                : "col-lg-4 col-md-6"
                            }
                          >
                            <div className={"common-cat-vehical-card-inner"}>
                              <div className="cat-body w-100">
                                <div className="head-with-img">
                                  <div className="left img">
                                    <img src={Cat_ye_car} alt="" />
                                  </div>
                                  <div className="right v-name cat-body-discription">
                                    <label htmlFor="">
                                      {t("Vehicle Name")}
                                    </label>
                                    <p>Volvo Transport</p>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">
                                      {t("Vehicle Number")}
                                    </label>
                                    <p>MH-12-5022</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">{t("Driver Name")}</label>
                                    <p>Mr. John Doe</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">{t("IMEI No.")}</label>
                                    <p>1234567894561230</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">
                                      {t("Vehicle Type")}
                                    </label>
                                    <p>Truck</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">
                                      {t("Vehicle Capacity")}
                                    </label>
                                    <p>60</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>{" "}
                          <div
                            className={
                              sidebar
                                ? "col-lg-6 col-md-6"
                                : "col-lg-4 col-md-6"
                            }
                          >
                            <div className={"common-cat-vehical-card-inner"}>
                              <div className="cat-body w-100">
                                <div className="head-with-img">
                                  <div className="left img">
                                    <img src={Cat_ye_car} alt="" />
                                  </div>
                                  <div className="right v-name cat-body-discription">
                                    <label htmlFor="">
                                      {t("Vehicle Name")}
                                    </label>
                                    <p>Volvo Transport</p>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">
                                      {t("Vehicle Number")}
                                    </label>
                                    <p>MH-12-5022</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">{t("Driver Name")}</label>
                                    <p>Mr. John Doe</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">{t("IMEI No.")}</label>
                                    <p>1234567894561230</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">
                                      {t("Vehicle Type")}
                                    </label>
                                    <p>Truck</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">
                                      {t("Vehicle Capacity")}
                                    </label>
                                    <p>60</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>{" "}
                          <div
                            className={
                              sidebar
                                ? "col-lg-6 col-md-6"
                                : "col-lg-4 col-md-6"
                            }
                          >
                            <div className={"common-cat-vehical-card-inner"}>
                              <div className="cat-body w-100">
                                <div className="head-with-img">
                                  <div className="left img">
                                    <img src={Cat_ye_car} alt="" />
                                  </div>
                                  <div className="right v-name cat-body-discription">
                                    <label htmlFor="">
                                      {t("Vehicle Name")}
                                    </label>
                                    <p>Volvo Transport</p>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">
                                      {t("Vehicle Number")}
                                    </label>
                                    <p>MH-12-5022</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">{t("Driver Name")}</label>
                                    <p>Mr. John Doe</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">{t("IMEI No.")}</label>
                                    <p>1234567894561230</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">
                                      {t("Vehicle Type")}
                                    </label>
                                    <p>Truck</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">
                                      {t("Vehicle Capacity")}
                                    </label>
                                    <p>60</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>{" "}
                          <div
                            className={
                              sidebar
                                ? "col-lg-6 col-md-6"
                                : "col-lg-4 col-md-6"
                            }
                          >
                            <div className={"common-cat-vehical-card-inner"}>
                              <div className="cat-body w-100">
                                <div className="head-with-img">
                                  <div className="left img">
                                    <img src={Cat_ye_car} alt="" />
                                  </div>
                                  <div className="right v-name cat-body-discription">
                                    <label htmlFor="">
                                      {t("Vehicle Name")}
                                    </label>
                                    <p>Volvo Transport</p>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">
                                      {t("Vehicle Number")}
                                    </label>
                                    <p>MH-12-5022</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">{t("Driver Name")}</label>
                                    <p>Mr. John Doe</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">{t("IMEI No.")}</label>
                                    <p>1234567894561230</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">
                                      {t("Vehicle Type")}
                                    </label>
                                    <p>Truck</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">
                                      {t("Vehicle Capacity")}
                                    </label>
                                    <p>60</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <p className="reg-color mt-3">
                    {t("Showing")} 1 - 10 {t("of")} 200
                  </p>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="Five">
                <div className="main-master-wrapper mb-0 inner-tabs-section overflow-hidden">
                  <div id="scroll_insideThe_Padding">
                    <div className="all-vehicle-main">
                      <div className="all-vehical-head row vehicle-top-inputs">
                        <div className="input-section-wrapper">
                          <div className="row">
                            <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Vehicle Name,No., Reg. No, IMEI..."
                              />
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Vehicle Category"
                              />
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Vehicle Capacity"
                              />
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12 mb-3 innerSelectBox weekcounder">
                              <select
                                required
                                className="form-select"
                                aria-label="Default select example"
                                placeholder="Transportation Type"
                              >
                                <option value="">Transportation Type</option>
                                <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="right-export-btn-section-wrapper">
                          <div className="c-pointer me-2">
                            <img src={Export} alt="" />
                          </div>
                          <div className="c-pointer">
                            <img src={Import} alt="" />
                          </div>
                        </div>
                      </div>

                      <div className="yauto" id="arrange-paading">
                        <div className="row main-cards-wrapper gx-3">
                          <div
                            className={
                              sidebar
                                ? "col-lg-6 col-md-6"
                                : "col-lg-4 col-md-6"
                            }
                          >
                            <div className={"common-cat-vehical-card-inner"}>
                              <div className="cat-body w-100">
                                <div className="head-with-img">
                                  <div className="left img">
                                    <img src={Cat_ye_car} alt="" />
                                  </div>
                                  <div className="right v-name cat-body-discription">
                                    <label htmlFor="">
                                      {t("Vehicle Name")}
                                    </label>
                                    <p>Volvo Transport</p>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">
                                      {t("Vehicle Number")}
                                    </label>
                                    <p>MH-12-5022</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">{t("Driver Name")}</label>
                                    <p>Mr. John Doe</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">{t("IMEI No.")}</label>
                                    <p>1234567894561230</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">
                                      {t("Vehicle Type")}
                                    </label>
                                    <p>Truck</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">
                                      {t("Vehicle Capacity")}
                                    </label>
                                    <p>60</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className={
                              sidebar
                                ? "col-lg-6 col-md-6"
                                : "col-lg-4 col-md-6"
                            }
                          >
                            <div className={"common-cat-vehical-card-inner"}>
                              <div className="cat-body w-100">
                                <div className="head-with-img">
                                  <div className="left img">
                                    <img src={Cat_ye_car} alt="" />
                                  </div>
                                  <div className="right v-name cat-body-discription">
                                    <label htmlFor="">
                                      {t("Vehicle Name")}
                                    </label>
                                    <p>Volvo Transport</p>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">
                                      {t("Vehicle Number")}
                                    </label>
                                    <p>MH-12-5022</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">{t("Driver Name")}</label>
                                    <p>Mr. John Doe</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">{t("IMEI No.")}</label>
                                    <p>1234567894561230</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">
                                      {t("Vehicle Type")}
                                    </label>
                                    <p>Truck</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">
                                      {t("Vehicle Capacity")}
                                    </label>
                                    <p>60</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>{" "}
                          <div
                            className={
                              sidebar
                                ? "col-lg-6 col-md-6"
                                : "col-lg-4 col-md-6"
                            }
                          >
                            <div className={"common-cat-vehical-card-inner"}>
                              <div className="cat-body w-100">
                                <div className="head-with-img">
                                  <div className="left img">
                                    <img src={Cat_ye_car} alt="" />
                                  </div>
                                  <div className="right v-name cat-body-discription">
                                    <label htmlFor="">
                                      {t("Vehicle Name")}
                                    </label>
                                    <p>Volvo Transport</p>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">
                                      {t("Vehicle Number")}
                                    </label>
                                    <p>MH-12-5022</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">{t("Driver Name")}</label>
                                    <p>Mr. John Doe</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">{t("IMEI No.")}</label>
                                    <p>1234567894561230</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">
                                      {t("Vehicle Type")}
                                    </label>
                                    <p>Truck</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">
                                      {t("Vehicle Capacity")}
                                    </label>
                                    <p>60</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>{" "}
                          <div
                            className={
                              sidebar
                                ? "col-lg-6 col-md-6"
                                : "col-lg-4 col-md-6"
                            }
                          >
                            <div className={"common-cat-vehical-card-inner"}>
                              <div className="cat-body w-100">
                                <div className="head-with-img">
                                  <div className="left img">
                                    <img src={Cat_ye_car} alt="" />
                                  </div>
                                  <div className="right v-name cat-body-discription">
                                    <label htmlFor="">
                                      {t("Vehicle Name")}
                                    </label>
                                    <p>Volvo Transport</p>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">
                                      {t("Vehicle Number")}
                                    </label>
                                    <p>MH-12-5022</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">{t("Driver Name")}</label>
                                    <p>Mr. John Doe</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">{t("IMEI No.")}</label>
                                    <p>1234567894561230</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">
                                      {t("Vehicle Type")}
                                    </label>
                                    <p>Truck</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">
                                      {t("Vehicle Capacity")}
                                    </label>
                                    <p>60</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>{" "}
                          <div
                            className={
                              sidebar
                                ? "col-lg-6 col-md-6"
                                : "col-lg-4 col-md-6"
                            }
                          >
                            <div className={"common-cat-vehical-card-inner"}>
                              <div className="cat-body w-100">
                                <div className="head-with-img">
                                  <div className="left img">
                                    <img src={Cat_ye_car} alt="" />
                                  </div>
                                  <div className="right v-name cat-body-discription">
                                    <label htmlFor="">
                                      {t("Vehicle Name")}
                                    </label>
                                    <p>Volvo Transport</p>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">
                                      {t("Vehicle Number")}
                                    </label>
                                    <p>MH-12-5022</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">{t("Driver Name")}</label>
                                    <p>Mr. John Doe</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">{t("IMEI No.")}</label>
                                    <p>1234567894561230</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">
                                      {t("Vehicle Type")}
                                    </label>
                                    <p>Truck</p>
                                  </div>
                                  <div className="col-lg-6 cat-body-discription mt-2">
                                    <label htmlFor="">
                                      {t("Vehicle Capacity")}
                                    </label>
                                    <p>60</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <p className="reg-color mt-3">
                    {t("Showing")} 1 - 10 {t("of")} 200
                  </p>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>

          <Modal
            show={show}
            onHide={handleClose}
            centered
            className="common-model"
          >
            <Modal.Header closeButton>
              <Modal.Title>{t("Delete Vehicle")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {t("Are you sure you want to delete this vehicle")} ?
            </Modal.Body>
            <Modal.Footer className="pop-up-modal-footer btn-wrapper">
              <button className="cx-btn-1" onClick={handleClose}>
                {t("Close")}
              </button>
              <button className="cx-btn-2" onClick={handleClose}>
                {t("Yes")}
              </button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </motion.div>
  );
};

export default OfferVehicleMarketPlace;
