import { React, useContext, useEffect, useState } from 'react'
import { AppContext } from '../../../context/AppContext'
import DDlogo from '../../../assets/images/smallDD.svg'
import Calendar from '../../../assets/images/calendar.svg'
import DatePicker from 'react-datepicker'
import { Link } from 'react-router-dom'
import SideIc from '../../../assets/images/sideBar.svg'
import Delete from '../../../assets/images/delete.svg'
import View from '../../../assets/images/Group.svg'
import EditIc from '../../../assets/images/ic-edit.svg'
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap'
import export_icon from '../../../assets/images/export_icon.svg'
import Form from 'react-bootstrap/Form'
import { motion } from 'framer-motion'
import Pagenation from '../../../sharedComponent/Pagenation'
import ApiConfig from '../../../api/ApiConfig'
import { simpleDeleteCall, simplePostCall } from '../../../api/ApiServices'
import { notifyError, notifySuccess } from '../../../sharedComponent/notify'
import { jsPDF } from 'jspdf'
import { DateDDMMYYYY } from '../../../sharedComponent/common'
import Loader from '../../../sharedComponent/Loader'
import NoDataComp from '../../../sharedComponent/NoDataComp'

import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
const VehicleFine = () => {
  const accessRights = useSelector(state => state.auth.accessRights)
  // const userRole = accessRights && accessRights.rights_role
  const userRole = "no Role"
  const { sidebar, setSidebar, Dark, setDark, loading, setLoading ,recordsPerPage} =
    useContext(AppContext)
  const [startDate, setStartDate] = useState(new Date())
  const [deleteModal, setDeleteModal] = useState(false)
  const { t, i18n } = useTranslation()

  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 }
  }
  const renderTooltipForEdit = props => (
    <Tooltip id='button-tooltip' {...props}>
      {t('Edit')}
    </Tooltip>
  )
  const renderTooltipForView = props => (
    <Tooltip id='button-tooltip' {...props}>
      {t('View')}
    </Tooltip>
  )
  const renderTooltipForDelete = props => (
    <Tooltip id='button-tooltip' {...props}>
      {t('Delete')}
    </Tooltip>
  )

  const [last_page, setlast_page] = useState(false)
  const [page, setPage] = useState(1)
  const [DeleteId, setDeleteId] = useState('')
  const [totalPages, setTotalPages] = useState(0)
  const [FilterName, setFiltertName] = useState('')
  const [FilterReason, setFiltertReason] = useState('')
  const [FilterDate, setFiltertDate] = useState('')
  const [FilterStatus, setFilterStatus] = useState('')
  const [FilterAmmount, setFilterAmmount] = useState('')
  const [spareList, setSpareList] = useState([])

  useEffect(() => {
    handalFilter()
  }, [FilterName, FilterReason, FilterStatus, FilterAmmount, FilterDate])

  useEffect(() => {
    if (page === 1) {
      setLoading(true)
      getSparepart(page)
    }
  }, [])

  function getSparepart (currentPage) {
    let newRequestBody = JSON.stringify({
      page: currentPage,
      page_limit:recordsPerPage
    })
    simplePostCall(ApiConfig.VEHICLE_FINE, newRequestBody)
      .then(data => {
        if (data.result) {
          setTotalPages(data.total_count)
          setlast_page(data.last_page)
          let coursesData = data.data ? data.data : []
          let LearningData = coursesData.map((eachteam, index) => {
            return {
              ...eachteam,
              user_profile_pic: eachteam.user_profile_pic || '',
              user_name: eachteam.user_name || '',
              user_email: eachteam.user_email || ''
            }
          })

          if (currentPage === 1) {
            setSpareList(data.data)
          } else {
            setSpareList([...spareList, ...LearningData])
            // setlast_page(false)
          }
        } else {
          setSpareList(0)
        }
      })
      .catch(err => {
        console.log('err', err)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  function userDelete () {
    let newRequestBody = JSON.stringify({
      fine_id: DeleteId
    })
    simpleDeleteCall(ApiConfig.VEHICLE_FINE_DELTE, newRequestBody)
      .then(data => {
        if (data.result) {
          notifySuccess(data.message)
          setDeleteModal(false)
          getSparepart(1)
          setPage(1)
        } else {
          notifyError(data.message)
        }
      })
      .catch(error => {
        console.log('api response', error)
      })
  }

  function handalFilter () {
    let newRequestBody = JSON.stringify({
      page: '',
      vehicle_name: FilterName,
      fine_reason: FilterReason,
      fine_date: FilterDate,
      fine_amount: FilterAmmount,
      page_limit:recordsPerPage
    })
    simplePostCall(ApiConfig.VEHICLE_FINE_FILTER, newRequestBody)
      .then(data => {
        if (data.result) {
          setTotalPages(data.total_count)
          setSpareList(data.data)
        } else {
          // notifyError(data.message);
          setSpareList(0)
          setTotalPages(0)
        }
      })
      .catch(error => {
        console.log('api response', error)
      })
  }

  function onPageLoad () {
    setPage(page + 1)
    getSparepart(page + 1)
  }

  function handalExport () {
    if (
      FilterName === '' ||
      FilterReason === '' ||
      FilterAmmount === '' ||
      FilterDate === ''
    ) {
      handalFilterExport()
    } else {
      getExportChat()
    }
  }

  function getExportChat () {
    simplePostCall(ApiConfig.VEHICLE_FINE_LIST_EXPORT)
      .then(data => {  
        if (data.result) {
          pdfFormat(data.data)
        } else {
        }
      })
      .catch(err => {
        console.log('err', err)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  function handalFilterExport () {
    let newRequestBody = JSON.stringify({
      vehicle_name: FilterName,
      fine_reason: FilterReason,
      fine_date: FilterDate,
      fine_amount: FilterAmmount
    })
    simplePostCall(ApiConfig.VEHICLE_FINE_FILTER_EXPORT, newRequestBody)
      .then(data => {
        if (data.result) {
          pdfFormat(data.data)
        } else {
        }
      })
      .catch(error => {
        console.log('api response', error)
      })
  }

  const pdfFormat = pdfData => {
// let chatsData = await getExportChat()
    const unit = 'pt'
    const size = 'A4' // Use A1, A2, A3 or A4
    const orientation = 'portrait' // portrait or landscape
    const marginLeft = 40
    const doc = new jsPDF(orientation, unit, size)
    doc.setFontSize(15)
    const title = 'Vehicle Fine'
    const headers = [['Sr. No.', 'Vehicle Name', 'Status', 'Amount']]
    var data = []

    pdfData.map((item, index) => {
      data.push([
        index + 1,
        item.vehicle_number,
        item.fine_status,
        item.fine_amount
      ])
    })

    let content = {
      headStyles: { fillColor: '#9c4900' },
      theme: 'grid',
      pageBreak: 'auto',
      bodyStyles: { fillColor: '#f6efe9' },
      styles: { fillColor: '#9c4900' },
      head: headers,
      title: title,
      body: data
    }

    doc.text(title, marginLeft, 25)
    doc.autoTable(content)
    doc.save('VT.pdf')
    return <div></div>
  }

  return (
    <>
      <motion.div
        className={sidebar ? 'taskMain ' : 'cx-active taskMain'}
        id='cx-main'
        variants={aninations}
        initial='initial'
        animate='animate'
        exit='exit'
        transition={{ duration: 0.1 }}
      >
    
            <div id='cx-wrapper' className='Vehicle_Fine'>
              {/* Top inputs for instatnt search */}
              <div className='displayFlexInp'>
                <div className='innerSelectBox weekCounter selectForm form_input_main'>
                  <Form.Control
                    required
                    type='text'
                    placeholder='Vehicle Name'
                    className='innerCust'
                    value={FilterName}
                    onChange={e => {
                      setFiltertName(e.target.value)
                    }}
                  />
                </div>
                <div className='innerSelectBox weekCounter selectForm form_input_main'>
                  <Form.Control
                    required
                    type='text'
                    value={FilterReason}
                    placeholder='Reason, Status'
                    className='innerCust'
                    onChange={e => {
                      setFiltertReason(e.target.value)
                    }}
                  />
                </div>
                <div className='innerSelectBox weekCounter selectForm form_input_main'>
                  <Form.Control
                    required
                    type='text'
                    placeholder='Amount'
                    className='innerCust'
                    value={FilterAmmount}
                    onChange={e => {
                      const inputValue = e.target.value.replace(/[^0-9]/g, '');
                      setFilterAmmount(inputValue)
                    }}
                  />
                </div>
                <div className='innerSelectBox weekCounter selectForm form_input_main datepicker-main'>
                  <input
                    class='datepicker-here form-control digits'
                    type='date'
                    data-language='en'
                    placeholder='MM-DD-YYYY'
                    data-position='top left'
                    name='submission_date'
                    value={FilterDate}
                    // onChange={(date) => setStartDate(date)}
                    onChange={e => setFiltertDate(e.target.value)}
                  />
                </div>
                <div className='innerSelectBox weekCounter selectForm form_input_main'>
                  {userRole === 'customer' ||
                  (accessRights && accessRights?.rights_manage_fine) ? (
                    <Link to='/AddVehicleFine' className='AddAccidentLink'>
                      <button className='innerCust'>+ {t('Add Fine')}</button>
                    </Link>
                  ) : null}
                </div>
                {/* <div className='headerDivIc form_input_main'>
                  <Link to='#'>
                    <img src={SideIc} alt='' />
                  </Link>
                </div> */}
                <div className='headerDivIc form_input_main'>
                  <Link to='#'>
                    <img
                      onClick={() => handalExport()}
                      src={export_icon}
                      alt=''
                    />
                  </Link>
                </div>
              </div>

              {/* Vehicle table */}
              {loading ? (
          <Loader />
        ) : (
          <>      <div className='main-master-wrapper'>
                <div
                  id='scroll_insideThe_Padding_tabel'
                  onScroll={e => {
                    const bottom =
                      e.target.scrollHeight - e.target.scrollTop ===
                      e.target.clientHeight

                    if (bottom && !last_page) onPageLoad()
                  }}
                >
                  <div className='VA_table'>
                    <table className='table tableAdmin'>
                      <thead className='tableHead'>
                        <tr>
                          <th>{t('Sr.no')}</th>
                          <th>{t('Vehicle Name')}</th>
                          <th>{t('Reason')}</th>
                          <th>{t('Status')}</th>
                          <th>{t('Amount')}</th>
                          <th>{t('Date')}</th>
                          <th>{t('Action')}</th>
                        </tr>
                      </thead>
                      {spareList && spareList.length > 0 ? (
                        spareList.map((itemlist, index) => {
                          return (
                            <tbody className='tableBody'>
                              <tr>
                                <td>{index + 1}</td>
                                <td>{itemlist.vehicle_number}</td>
                                <td>{itemlist.fine_reason}</td>
                                <td>{itemlist.fine_status}</td>
                                <td>{itemlist.fine_amount}</td>
                                <td>{DateDDMMYYYY(itemlist.fine_date)}</td>
                                <td>
                                  <div className='innerFlex d-flex'>
                                    <OverlayTrigger
                                      placement='bottom'
                                      delay={{ show: 250, hide: 400 }}
                                      overlay={renderTooltipForView}
                                    >
                                      <Link
                                        // to="/ViewVehicleFine"
                                        to={
                                          '/ViewVehicleFine/' + itemlist.fine_id
                                        }
                                      >
                                        <div className='inconsIn me-3'>
                                          <img src={View} alt='' />
                                        </div>
                                      </Link>
                                    </OverlayTrigger>
                                    {userRole === 'customer' ||
                                    (accessRights &&
                                      accessRights?.rights_manage_fine) ? (
                                      <>
                                        <OverlayTrigger
                                          placement='bottom'
                                          delay={{ show: 250, hide: 400 }}
                                          overlay={renderTooltipForEdit}
                                        >
                                          <Link
                                            to={
                                              '/AddVehicleFine/' +
                                              itemlist.fine_id
                                            }
                                          >
                                            <div className='inconsIn me-3'>
                                              <img src={EditIc} alt='' />
                                            </div>
                                          </Link>
                                        </OverlayTrigger>
                                        <OverlayTrigger
                                          placement='bottom'
                                          delay={{ show: 250, hide: 400 }}
                                          overlay={renderTooltipForDelete}
                                        >
                                          <Link
                                            to='#'
                                            onClick={() => {
                                              setDeleteId(itemlist.fine_id)
                                              setDeleteModal(true)
                                            }}
                                          >
                                            <div className='inconsIn'>
                                              <img src={Delete} alt='' />
                                            </div>
                                          </Link>
                                        </OverlayTrigger>
                                      </>
                                    ) : null}
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          )
                        })
                      ) : (
                        <></>
                      )}
                    </table>
                  </div>
                  {spareList.length > 0 ? '' : <NoDataComp />}
                </div>
                {/* {last_page === true ? (
                  <p
                    style={{
                      textAlign: 'center',
                      marginTop: '20px',
                      color: '#9c4900'
                    }}
                  >
                    <b>No More data Found</b>
                  </p>
                ) : (
                  ''
                )} */}
           {spareList?.length>0 && <Pagenation length={spareList?.length} total={totalPages} /> }
              </div>
              </>
        )}
            </div>
       
      </motion.div>

      {/* Delete Modal Start */}
      <Modal
        show={deleteModal}
        onHide={() => setDeleteModal(false)}
        centered
        className='common-model'
      >
        <Modal.Header closeButton>
          <Modal.Title>{t('Delete Vehicle Fine')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t('Are you sure you want to Delete this Vehicle Fine')} ?
        </Modal.Body>
        <Modal.Footer className='pop-up-modal-footer'>
          <div class='btn-wrapper'>
            <button className='cx-btn-1' onClick={() => setDeleteModal(false)}>
              {t('Cancel')}
            </button>
            <button className='cx-btn-2' onClick={userDelete}>
              Yes
            </button>
          </div>
        </Modal.Footer>
      </Modal>
      {/* Delete Modal End */}
    </>
  )
}

export default VehicleFine
