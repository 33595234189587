import { React, useContext, useState, useEffect } from "react";
import { AppContext } from "../../../context/AppContext";
import Form from "react-bootstrap/Form";
import Save from "../../../assets/images/save.svg";
import Inspect from "../../../assets/images/inspect.svg";
import Right from "../../../assets/images/right.svg";
import Invoice from "../../../assets/images/invoice.svg";
import DatePicker from "react-datepicker";
import Calendar from "../../../assets/images/calendar.svg";
import uploadIcon from "../../../assets/images/uploadIcon.svg";
import { motion } from "framer-motion";
import { notifyError, notifySuccess } from "../../../sharedComponent/notify";
import ApiConfig from "../../../api/ApiConfig";
import CommonDatePicker from "../../../sharedComponent/CommonDatePicker";

import {
  getWithAuthCall,
  multipartPostCall,
  putMultipartWithAuthCall,
  putWithAuthCall,
  simpleGetCallWithErrorResponse,
  simplePUTCall,
  simplePostCall,
} from "../../../api/ApiServices";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import Loader from "../../../sharedComponent/Loader";
import { useTranslation } from "react-i18next";
// Types of files

const AddVehicleAccident = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { sidebar, setSidebar, Dark, setDark } = useContext(AppContext);
  const [validated, setValidated] = useState(false);
  const [AddAccident, setAddAccident] = useState({
    vehicle_id: "",
    location: "",
    expenses: "",
    driver_id: "",
    route_id: "",
    description: "",
    invoice: "",
    date: "",
  });

  const handleCancle = () => {
    navigate("/VehicleAccident")
    setAddAccident({

      date: "",
    });
  };
  const [DeliverType, setDeliverType] = useState([]);
  const [VehicalType, setVehicalType] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const { t, i18n } = useTranslation();

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      HandalAccident(event);
    }
    setValidated(true);
  };
  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
  };

  const params = useParams();
  let UserId = params.id;

  useEffect(() => {
    if (UserId) {
      geAccidentDetails();
      geDeliverAllList();
      geVehicalList();
    } else {
      geDeliverAllList();
      geVehicalList();
    }
  }, []);

  const geAccidentDetails = () => {
    setLoading(true);
    let newRequestBody = JSON.stringify({
      incident_id: UserId.toString(),
    });
    simplePostCall(ApiConfig.VEHICLE_PROFLIE, newRequestBody)
      .then((res) => {
        let user_profile = res.data;
        setAddAccident({
          vehicle_id: user_profile.incident_vehicle_id,
          location: user_profile.incident_location,
          expenses: user_profile.incident_expense,
          driver_id: user_profile.incident_driver_id,
          // route_id: user_profile.route_id,
          description: user_profile.incident_description,
          invoice: user_profile.incident_invoice_no,
          date: moment(user_profile.incident_date).utc().format("YYYY-MM-DD"),
        });
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const HandalAccident = (e) => {
    if (UserId) {
      UpdateAccident(e);
    } else {
      AddCreateAccident(e);
    }
  };

  const UpdateAccident = (e) => {
    e.preventDefault();

    let newRequestBody = JSON.stringify({
      incident_id: UserId,
      date: moment(AddAccident.date).utc().format("YYYY-MM-DD"),
    });

    simplePUTCall(ApiConfig.VEHICLE_ACCIDENT_UPDATE, newRequestBody)
      .then((res) => {
        if (res.result) {
          notifySuccess(res.message);
          navigate("/VehicleAccident");
        } else {
          notifyError(res.message);
        }
      })
      .catch((err) => console.log(err));
  };

  const AddCreateAccident = (e) => {
    e.preventDefault();
    let formData = new FormData();
    formData.append("vehicle_id", AddAccident.vehicle_id);
    formData.append("location", AddAccident.location);
    formData.append("expenses", AddAccident.expenses);
    formData.append("driver_id", AddAccident.driver_id);
    // formData.append("route_id", AddAccident.route_id);
    formData.append("description", AddAccident.description);
    formData.append("invoice", AddAccident.invoice);
    formData.append(
      "date",
      moment(AddAccident.date).utc().format("YYYY-MM-DD")
    );

    multipartPostCall(ApiConfig.VEHICLE_ASSISTANTS_ADD, formData)
      .then((res) => {
        if (res.result) {
          notifySuccess(res.message);
          navigate("/VehicleAccident");
        } else {
          notifyError(res.message);
        }
      })
      .catch((err) => console.log(err));
  };

  const handleChange = (e) => {
    if (e.target.name === "invoice") {
      setAddAccident({
        ...AddAccident,
        [e.target.name]: e.target.files[0],
      });
    } else setAddAccident({ ...AddAccident, [e.target.name]: e.target.value });
  };

  function geDeliverAllList() {
    getWithAuthCall(ApiConfig.VEHICLE_ACCIDENT_DRAIVER_LIST)
      .then((data) => {
        setDeliverType(data.data);
      })
      .catch((error) => {
        console.log("api response", error);
      });
  }
  function geVehicalList() {
    getWithAuthCall(ApiConfig.VEHICLE_ACCIDENT_VEHICALE_LIST)
      .then((data) => {
         setVehicalType(data?.data);
      })
      .catch((error) => {
        console.log("api response", error);
      });
  }
  return (
    <>
      <motion.div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
        variants={aninations}
        initial="initial"
        animate="animate"
        exit="exit"
        transition={{ duration: 0.1 }}
      >
        {loading ? (
          <Loader />
        ) : (
          <>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <div id="cx-wrapper" className="AddVehicle_Accident">
                <div className="main-master-wrapper">
                  <div className="vehicleHead">
                    <p> {UserId ? "Update" : "New"} Vehicle Incident Details</p>
                  </div>
                  <div className="innerInputsGen mainVehAccident ">
                    <div className="addVehicleSe">
                      <div className="addVehicleLeftSec">
                        <div className="row">
                          <div className="col-md-12 form_input_main">
                            <div className="innerSelectBox weekCounter">
                              <Form.Label className="headinglabelVehi">
                                {t("Vehicle Name")} <span>&#42;</span>{" "}
                              </Form.Label>

                              <Form.Select
                                disabled={UserId ? true : false}
                                required
                                placeholder="Enter Your vehicle Name"
                                value={AddAccident.vehicle_id}
                                onChange={(e) => {
                                  setAddAccident({
                                    ...AddAccident,
                                    vehicle_id: e.target.value,
                                  });
                                }}
                              >
                                 <option value="">Please select vehicle Name</option>
                                {VehicalType &&
                                  VehicalType.length &&
                                  VehicalType.map((Typelist, index) => {
                                    return (
                                      <>
                                       
                                        <option
                                          key={"delivery" + index}
                                          value={Typelist.vehicle_id}
                                        >
                                          {Typelist.vehicle_number}
                                        </option>
                                      </>
                                    );
                                  })}
                              </Form.Select>
                              <Form.Control.Feedback type="invalid">
                              Please select vehicle Name
                              </Form.Control.Feedback>
                            </div>
                          </div>
                          <div className="col-md-12 form_input_main">
                            <div className="innerSelectBox weekCounter">
                              <Form.Label className="common-labels">
                                {" "}
                                {t("Location")} <span>&#42;</span>{" "}
                              </Form.Label>
                              <Form.Control
                                required
                                type="text"
                                disabled={UserId ? true : false}
                                placeholder="Enter Your Location"
                                value={AddAccident.location}
                                onChange={(e) => {
                                  setAddAccident({
                                    ...AddAccident,
                                    location: e.target.value,
                                  });
                                }}
                              />
                              <Form.Control.Feedback type="invalid">
                                Please Enter Your Location.
                              </Form.Control.Feedback>
                            </div>
                          </div>
                          <div className="col-md-12 form_input_main">
                            <div className="innerSelectBox weekCounter">
                              <Form.Label className="common-labels">
                                {" "}
                                {t("Expenses")} <span>&#42;</span>{" "}
                              </Form.Label>
                              <Form.Control
                                disabled={UserId ? true : false}
                                required
                                type="text"
                                placeholder="Enter Your Amount"
                                value={AddAccident.expenses}
                                onChange={(e) => {
                                  const inputValue = e.target.value.replace(/[^0-9]/g, '');
                                  setAddAccident({
                                    ...AddAccident,
                                    expenses: inputValue,
                                  });
                                }}
                              />
                              <Form.Control.Feedback type="invalid">
                                Please Enter Your Amount.
                              </Form.Control.Feedback>
                            </div>
                          </div>
                          <div className="col-md-12 form_input_main">
                            <div className="innerSelectBox weekCounter">
                              <Form.Label className="common-labels">
                                {t("Driver")} <span>&#42;</span>{" "}
                              </Form.Label>

                              <Form.Select
                                disabled={UserId ? true : false}
                                required
                                placeholder="Enter Driver Name"
                                value={AddAccident.driver_id}
                                onChange={(e) => {
                                  setAddAccident({
                                    ...AddAccident,
                                    driver_id: e.target.value,
                                  });
                                }}
                              >
                                <option value=""> Please Select Driver Name</option>
                                {DeliverType &&
                                  DeliverType.length &&
                                  DeliverType.map((Typelist, index) => {
                                    return (
                                      <>
                                        
                                        <option
                                          key={"delivery" + index}
                                          value={Typelist.driver_id}
                                        >
                                          {Typelist.driver_name}
                                        </option>
                                      </>
                                    );
                                  })}
                              </Form.Select>
                              <Form.Control.Feedback type="invalid">
                                Please Select Driver Name.
                              </Form.Control.Feedback>
                            </div>
                          </div>
                          <div className="col-md-12 form_input_main">
                            <div className="innerSelectBox weekCounter">
                              <Form.Label className="common-labels">
                                {" "}
                                {t("Description")} <span>&#42;</span>{" "}
                              </Form.Label>
                              <Form.Control
                                disabled={UserId ? true : false}
                                as="textarea"
                                rows={3}
                                required
                                type="text"
                                placeholder="Type Description"
                                value={AddAccident.description}
                                onChange={(e) => {
                                  setAddAccident({
                                    ...AddAccident,
                                    description: e.target.value,
                                  });
                                }}
                              />
                              <Form.Control.Feedback type="invalid">
                                Please Type Description.
                              </Form.Control.Feedback>
                            </div>
                          </div>
                          <div className="col-md-12 form_input_main">
                          <Form.Label className="common-labels">
                                {" "}
                                {t("Date")}
                              </Form.Label>
                            <div className="innerSelectBox weekCounter datepicker-main">
                        
                             
                              <CommonDatePicker
                                setDate={setAddAccident}
                                data={AddAccident}
                                dateKey="date"
                              />

                              {/* <input
                        class="datepicker-here form-control digits"
                        type="date"
                        data-language="en"
                        placeholder="MM-DD-YYYY"
                        data-position="top left"
                        name="submission_date"
                        // min={moment().format("YYYY-MM-DD")}
                        value={AddAccident.date}
                        // onChange={(date) => setStartDate(date)}
                        onChange={(e) =>
                          setAddAccident({
                            ...AddAccident,
                            date: e.target.value,
                          })
                        }
/> */}
                              <Form.Control.Feedback type="invalid">
                                Please Select Date.
                              </Form.Control.Feedback>
                              {/* <img
                            src={Calendar}
                            className="addVehCalender"
                            alt=""
                          /> */}
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* This section is for image addidtion in an array on every selection */}
                      <div className="imagesRightSec">
                        <div className="row">
                          <div className="col-md-12 form_input_main">
                            {/* <div className="innerSelectBox weekCounter">
                          <label className="common-labels">Invoice</label>
                          
                          <div className="savedInvoice">
                            <label>Example Document-Invoice.pdf</label>
                          </div>
                          <img src={Save} className="save" alt="" />
      
                          <img src={Inspect} className="Inspect" alt="" />
                          <img src={Right} className="right" alt="" />
                          <label className="invalidText">
                            {t("Failed to upload")}
                          </label>
                          <label className="retry">Retry</label>
                        </div> */}
                          </div>
                          <div className="col-md-12 form_input_main mb-4">
                            {/* <div className="innerSelectBox weekCounter form_input_main">
                          <label className="common-labels">Invoice 2</label>
                      
                          <div className="savedInvoice">
                            <label>Example Document-Invoice.pdf</label>
                          </div>
                          <img src={Save} className="save" alt="" />
           
                          <img src={Inspect} className="Inspect" alt="" />
                          <img src={Right} className="right" alt="" />
                          <label className="invalidText">
                            {t("Failed to upload")}
                          </label>
                          <label className="retry">{t("Retry")}</label>
                        </div>
                        {/* Selected image preview here */}
                            <div className="previewImg">
                              <img
                                src={
                                  !AddAccident.invoice
                                    ? Invoice
                                    : AddAccident.invoice.length
                                      ? AddAccident.invoice
                                      : AddAccident.invoice &&
                                      URL.createObjectURL(AddAccident.invoice)
                                }
                                className="InvoiceImg"
                                alt=""
                              />
                            </div>
                          </div>
                     {  
                      // !UserId&&
                       <div className="col-md-12 form_input_main">
                            <div className="fileDropper">
                              <label htmlFor="" className="imageHolder">
                                {/* <input
                                  type="file"
                                  id="file"
                                  className="d-none"
                                /> */}
                                <div className="innerFlex">
                                  {/* <img
                                    src={uploadIcon}
                                    className="browseImg"
                                    alt=""
                                  />
                                  <p className="innerTxt">
                                    Drag & Drop Your File
                                  </p> */}
                                  {/* <p className="innerTxt">Or</p> */}
                                  <label
                                    htmlFor="invoice"
                                    className="browseBtn"
                                  >
                                    <input
                                      // disabled={UserId ? true : false}
                                      type="file"
                                      id="invoice"
                                      name="invoice"
                                      className="d-none"
                                      onChange={handleChange}
                                    />
                                    <p className="mt-1">Choose File</p>
                                  </label>
                                </div>
                              </label>
                            </div>
                          </div>}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Two Bottom Buttons */}
                  <div className="d-flex justify-content-end align-items-center belowBtns">
                    <button className="cx-btn-1"
                      type="button"

                      onClick={() => handleCancle()}>

                      Cancel</button>
                    <button className="cx-btn-2">
                      {UserId ? "Update" : "Submit"}
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          </>
        )}
      </motion.div >
    </>
  );
};

export default AddVehicleAccident;
