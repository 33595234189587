import { React, useContext, useState, useEffect } from "react";
import { AppContext } from "../../../context/AppContext";
import Form from "react-bootstrap/Form";
import Save from "../../../assets/images/save.svg";
import Inspect from "../../../assets/images/inspect.svg";
import Right from "../../../assets/images/right.svg";
import Invoice from "../../../assets/images/invoice.svg";
import DatePicker from "react-datepicker";
import Calendar from "../../../assets/images/calendar.svg";
import uploadIcon from "../../../assets/images/uploadIcon.svg";
import { motion } from "framer-motion";
import { useNavigate, useParams } from "react-router-dom";
import { getWithAuthCall, multipartPostCall, simpleGetCall } from "../../../api/ApiServices";
import ApiConfig from "../../../api/ApiConfig";
import moment from "moment";
import { notifyError, notifySuccess } from "../../../sharedComponent/notify";
import { useTranslation } from "react-i18next";
// Types of files
import CommonDatePicker from "../../../sharedComponent/CommonDatePicker";
import Loader from "../../../sharedComponent/Loader";

const AddVehicleSpareParts = () => {
  const { sidebar, setSidebar, Dark, setDark, loading, setLoading } = useContext(AppContext);
  const [validated, setValidated] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const navigate = useNavigate();
  const [VehicalType, setVehicalType] = useState([]);
  const params = useParams();
  let UserId = params.id;
  useEffect(() => {
    if (UserId) {
      geAccessoryDetails();
      geVehicalList()

    } else {
      geVehicalList()
    }
  }, []);
  const [AddAccessry, setAddAccessry] = useState({
    spareparts_name: "",
    spareparts_warranty: "",
    spareparts_expiry_date: "",
    spareparts_quantity: "",
    spareparts_amount: "",
    spareparts_purchase_date: "",
    spareparts_vehicle_id: "",
    spareparts_invoice_no: ""

  });
  const handleCancle = () => {
    setAddAccessry({

      spareparts_expiry_date: "",

      spareparts_purchase_date: "",

    });
  };
  const { t, i18n } = useTranslation();

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      HandalAccident(event)
    }
    setValidated(true);
  };
  const aninations = {
    initial: { opacity: 0, x: 400 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100 },
  };







  const geAccessoryDetails = () => {
    setLoading(true)
    simpleGetCall(ApiConfig.Vehicle_ACCESSORY_PROFLIE + UserId)
      .then((res) => {
        let user_profile = res.data;
        setAddAccessry({
          spareparts_name: user_profile.spareparts_name,
          spareparts_vehicle_id: user_profile.spareparts_vehicle_id,
          spareparts_invoice_no: user_profile.spareparts_invoice_no,
          spareparts_warranty: user_profile.spareparts_warranty,
          spareparts_expiry_date: moment(user_profile.spareparts_expiry_date).utc().format("YYYY-MM-DD"),
          spareparts_quantity: user_profile.spareparts_quantity,
          // route_id: user_profile.route_id,
          spareparts_amount: user_profile.spareparts_amount,
          spareparts_purchase_date: moment(user_profile.spareparts_purchase_date).utc().format("YYYY-MM-DD"),

        });
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const HandalAccident = (e) => {
    if (UserId) {
      UpdateAccessory(e);
    } else {
      AddCreateAccessory(e);
    }
  };

  const AddCreateAccessory = (e) => {
    e.preventDefault();
    let formData = new FormData();
    formData.append("spareparts_name", AddAccessry.spareparts_name);
    formData.append("spareparts_warranty", AddAccessry.spareparts_warranty);
    formData.append("spareparts_expiry_date", moment(AddAccessry.spareparts_expiry_date).utc().format("YYYY-MM-DD"));
    formData.append("spareparts_quantity", AddAccessry.spareparts_quantity);
    formData.append("spareparts_invoice_no", AddAccessry.spareparts_invoice_no);
    formData.append("spareparts_amount", AddAccessry.spareparts_amount);
    formData.append("spareparts_purchase_date", moment(AddAccessry.spareparts_purchase_date).utc().format("YYYY-MM-DD"));
    formData.append("spareparts_vehicle_id", AddAccessry.spareparts_vehicle_id);



    multipartPostCall(ApiConfig.VEHICLE_ACCESSORY_ADD, formData)
      .then((res) => {
        if (res.result) {
          notifySuccess(res.message);
          navigate("/VehicleSpareParts");
        } else {
          notifyError(res.message);
        }
      })
      .catch((err) => console.log(err));
  };

  const UpdateAccessory = (e) => {
    e.preventDefault();


    let formData = new FormData();
    formData.append("spareparts_id", UserId);
    formData.append("spareparts_invoice_no", AddAccessry.spareparts_invoice_no);
    formData.append("spareparts_expiry_date", moment(AddAccessry.spareparts_expiry_date).utc().format("YYYY-MM-DD"));

    formData.append("spareparts_purchase_date", moment(AddAccessry.spareparts_purchase_date).utc().format("YYYY-MM-DD"));



    multipartPostCall(ApiConfig.Vehicle_Accessory_update, formData)
      .then((res) => {
        if (res.result) {
          notifySuccess(res.message);
          navigate("/VehicleSpareParts");
        } else {
          notifyError(res.message);
        }
      })
      .catch((err) => console.log(err));
  };
  function geVehicalList() {
    getWithAuthCall(ApiConfig.VEHICLE_ACCIDENT_VEHICALE_LIST)
      .then((data) => {
     

        setVehicalType(data.data);
      })
      .catch((error) => {
        console.log("api response", error);
      });
  }
  const handleChange = (e) => {
    if (e.target.name === "spareparts_invoice_no") {
      setAddAccessry({
        ...AddAccessry,
        [e.target.name]: e.target.files[0],

      });
    } else setAddAccessry({ ...AddAccessry, [e.target.name]: e.target.value });
  };


  return (
    <>
      <motion.div
        className={sidebar ? "taskMain " : "cx-active taskMain"}
        id="cx-main"
        variants={aninations}
        initial="initial"
        animate="animate"
        exit="exit"
        transition={{ duration: 0.1 }} >
        {loading ? (
          <Loader />
        ) : (
          <>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <div id="cx-wrapper" className="AddVehicle_SpareParts">
                <div className="main-master-wrapper">
                  <div className="vehicleHead">
                    <p>{UserId ? <>{t("Update Vehicle Spare Part Details")}</> : <>{t(" New Vehicle Spare Part Details")}</>}</p>
                  </div>
                  <div className="innerInputsGen mainVehAccident ">
                    <div className="addVehicleSe">
                      <div className="addVehicleLeftSec">
                        <div className="row">
                          <div className="col-md-12 form_input_main">
                            <div className="innerSelectBox weekCounter">
                              <Form.Label className="common-labels">
                                {" "}
                                {t("Vehicle Name")} <span>&#42;</span>{" "}
                              </Form.Label>

                              <Form.Select
                                disabled={UserId ? true : false}
                                required
                                placeholder="Enter Your vehicle Name"
                                value={AddAccessry.spareparts_vehicle_id}
                                onChange={(e) => {
                                  setAddAccessry({
                                    ...AddAccessry,
                                    spareparts_vehicle_id: e.target.value,
                                  });
                                }}

                              >
                                 <option value="">Please select vehicle Name</option>
                                {VehicalType &&
                                  VehicalType.length &&
                                  VehicalType.map((Typelist, index) => {
                                    return (
                                      <>
                                        {/* <option value="select">Enter Your vehicle Name</option> */}
                                        <option
                                          key={"delivery" + index}
                                          value={Typelist.vehicle_id}
                                        >
                                          {Typelist.vehicle_number}
                                        </option>
                                      </>
                                    );
                                  })}
                              </Form.Select>
                              <Form.Control.Feedback type="invalid">
                              Please select vehicle Name
                              </Form.Control.Feedback>
                            </div>
                          </div>
                          <div className="col-md-12 form_input_main">
                            <div className="innerSelectBox weekCounter">
                              <Form.Label className="common-labels">
                                {" "}
                                {t("Spare Part Name")} <span>&#42;</span>{" "}
                              </Form.Label>
                              <Form.Control
                                required
                                type="text"
                                placeholder="Enter Spare Part Name"
                                disabled={UserId ? true : false}
                                value={AddAccessry.spareparts_name}
                                onChange={(e) => {
                                  setAddAccessry({
                                    ...AddAccessry,
                                    spareparts_name: e.target.value,
                                  });
                                }}
                              />
                              <Form.Control.Feedback type="invalid">
                                Please Enter Spare Part Name.
                              </Form.Control.Feedback>
                            </div>
                          </div>
                          <div className="col-md-12 form_input_main">
                            <div className="innerSelectBox weekCounter">
                              <Form.Label className="common-labels">
                                {" "}
                                {t("Quantity")} <span>&#42;</span>{" "}
                              </Form.Label>
                              <Form.Control
                                required
                                type="text"
                                placeholder="Enter Quantity"
                                disabled={UserId ? true : false}
                                value={AddAccessry?.spareparts_quantity}
                                onChange={(e) => {
                                  const inputValue = e.target.value.replace(/[^0-9]/g, '');
                                  setAddAccessry({
                                    ...AddAccessry,
                                    spareparts_quantity: inputValue,
                                  });
                                }}
                              />
                              <Form.Control.Feedback type="invalid">
                                Please Enter Quantity.
                              </Form.Control.Feedback>
                            </div>
                          </div>
                          <div className="col-md-12 form_input_main">
                            <div className="innerSelectBox weekCounter">
                              <Form.Label className="common-labels">
                                {" "}
                                {t("Price")} <span>&#42;</span>{" "}
                              </Form.Label>
                              <Form.Control
                                required
                                type="text"
                                placeholder="Enter Price"
                                disabled={UserId ? true : false}
                                value={AddAccessry.spareparts_amount}
                                onChange={(e) => {
                                  const inputValue = e.target.value.replace(/[^0-9]/g, '');
                                  setAddAccessry({
                                    ...AddAccessry,
                                    spareparts_amount:inputValue,
                                  });
                                }}
                              />
                              <Form.Control.Feedback type="invalid">
                                Please Enter Price.
                              </Form.Control.Feedback>
                            </div>
                          </div>
                          <div className="col-md-12 form_input_main">
                            <div className="innerSelectBox weekCounter">
                              <Form.Label className="common-labels">
                                {" "}
                                {t("Warranty")}  <span>&#42;</span>{" "}
                              </Form.Label>
                              <Form.Control
                                required
                                type="text"
                                placeholder="Enter Warranty"
                                disabled={UserId ? true : false}
                                value={AddAccessry.spareparts_warranty}
                                onChange={(e) => {
                                  setAddAccessry({
                                    ...AddAccessry,
                                    spareparts_warranty: e.target.value,
                                  });
                                }}
                              />
                              <Form.Control.Feedback type="invalid">
                                Please Enter Warranty.
                              </Form.Control.Feedback>
                            </div>
                          </div>

                          <div className="col-md-12 form_input_main">
                          <Form.Label className="common-labels">
                                {" "}
                                {t("Purchase Date")} <span>&#42;</span>
                              </Form.Label>
                            <div className="innerSelectBox weekCounter datepicker-main">
                           
                              <CommonDatePicker setDate={setAddAccessry} data={AddAccessry} dateKey="spareparts_purchase_date" />


                              <Form.Control.Feedback type="invalid">
                                Please Select Date.
                              </Form.Control.Feedback>
                          
                            </div>
                          </div>

                          <div className="col-md-12 form_input_main">
                          <Form.Label className="common-labels">
                                {" "}
                                {t("Expiry Date")} <span>&#42;</span>
                              </Form.Label>
                            <div className="innerSelectBox weekCounter datepicker-main">
                           
                              <CommonDatePicker setDate={setAddAccessry} data={AddAccessry} dateKey="spareparts_expiry_date" />


                              <Form.Control.Feedback type="invalid">
                                {t("Please Select Date.")}
                              </Form.Control.Feedback>
                     
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* This section is for image addidtion in an array on every selection */}
                      <div className="imagesRightSec">
                        <div className="row">

                          <div className="col-md-12 form_input_main">

                            {/* Selected image preview here */}
                            <div className="previewImg">
                              <img
                                src={
                                  !AddAccessry.spareparts_invoice_no
                                    ? Invoice
                                    : AddAccessry.spareparts_invoice_no.length
                                      ? AddAccessry.spareparts_invoice_no
                                      : AddAccessry.spareparts_invoice_no &&
                                      URL.createObjectURL(
                                        AddAccessry.spareparts_invoice_no
                                      )
                                }
                                className="InvoiceImg" alt="" />
                            </div>
                          </div>
                          <div className="col-md-12 form_input_main">
                            <div className="fileDropper">
                              <label htmlFor="" className="imageHolder">
                                {/* <input type="file" id="file" className="d-none" /> */}
                                <div className="innerFlex">
                                  {/* <img
                                    src={uploadIcon}
                                    className="browseImg"
                                    alt=""
                                  />
                                  <p className="innerTxt">{t("Drag & Drop Your File")}</p>
                                  <p className="innerTxt">{t("Or")}</p> */}
                                  <label htmlFor="spareparts_invoice_no" className="browseBtn">
                                    <input
                                      // disabled={UserId? true : false}
                                      type="file"


                                      id="spareparts_invoice_no"
                                      name="spareparts_invoice_no"

                                      className="d-none"
                                      onChange={handleChange}
                                    />
                                    <p className="mt-1">{t("Browse File")}</p>
                                  </label>
                                </div>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Two Bottom Buttons */}
                  <div className="d-flex justify-content-end align-items-center belowBtns">
                    <button className="cx-btn-1" type="button"
                      onClick={() => handleCancle()}>{t("Cancel")}</button>
                    <button className="cx-btn-2">{UserId ? <>{t("Update")}</> : <>{t("Submit")}</>}</button>
                  </div>
                </div>
              </div>
            </Form>
          </>)}
      </motion.div>
    </>
  );
};

export default AddVehicleSpareParts;
